import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray, merge } from 'lodash-es'
import { Label, Paragraph } from 'components/primitives'
import { DynamoEvents, EventTypes } from 'types/events'
import { DispatchHeader } from 'components/Dispatch'
import DispatchService from 'components/Dispatch/DispatchService'
import DispatchUlyService from 'components/Dispatch/DispatchUlyService'
import IncidentLocation from 'components/IncidentLocation'
import { useAAADispatch } from 'hooks/aaa'
import SoftServiceUpdateForm from './SoftServiceUpdateForm'
import SoftServiceUpdateSteps from './SoftServiceUpdateSteps'
import { BreakdownOptions } from './BreakdownDetails/BreakdownRadio'
import { AnyObject, LocationPayload } from 'types/location'
import { useSelector } from 'react-redux'
import { ReduxState, selectCurrentEvent } from 'redux/appStore'
import { StandardServiceCall } from 'types/aaa'
import { PartialULYDispatch } from 'types/urgently'

type Props = {
  title: string
  children?: React.ReactNode
} & DynamoEvents & { dispatch?: StandardServiceCall; case?: PartialULYDispatch }

const SoftServiceEvent: React.FC<Props> = ({ title, children, ...rest }) => {
  const { t } = useTranslation()
  const [edit, setEdit] = React.useState<boolean>(false)
  let attributes = (rest.attributes || {}) as AnyObject

  // Grab the additional services
  const ldrEvent = useSelector((state: ReduxState) => selectCurrentEvent(state)(EventTypes.LDR))
  const towEvent = useSelector((state: ReduxState) => selectCurrentEvent(state)(EventTypes.TOW))
  const exchangeEvent = useSelector((state: ReduxState) => selectCurrentEvent(state)(EventTypes.EXCHANGE))
  const recoveryEvent = useSelector((state: ReduxState) => selectCurrentEvent(state)(EventTypes.RECOVERY))

  if (ldrEvent && ldrEvent.event) attributes = merge({}, attributes, ldrEvent.event.attributes)
  if (towEvent && towEvent.event) attributes = merge({}, attributes, towEvent.event.attributes)
  if (recoveryEvent && recoveryEvent.event) attributes = merge({}, attributes, recoveryEvent.event.attributes)
  if (exchangeEvent && exchangeEvent.event) attributes = merge({}, attributes, exchangeEvent.event.attributes)

  // <SoftService>
  const callKey = isArray(attributes?.dispatch) ? attributes?.dispatch?.[0]?.callKey : null
  const caseId = attributes?.serviceCase ? attributes?.serviceCase?.caseId : null
  const { dispatch: aaaDispatch } = useAAADispatch(callKey)
  const { make, model, licensePlate, year } = attributes?.vehicle || {}

  if (edit) {
    const event = { ...rest }
    return (
      <>
        <DispatchHeader
          title={title}
          dispatchStatus={aaaDispatch?.service?.status?.status}
          edit={edit}
          readOnly={false}
          onClick={() => {
            setEdit(!edit)
          }}
        />
        <SoftServiceUpdateForm dispatchedEvent={event} onUpdated={() => setEdit(false)}>
          <SoftServiceUpdateSteps />
        </SoftServiceUpdateForm>
      </>
    )
  }

  return (
    <>
      <DispatchHeader
        title={title}
        dispatchStatus={aaaDispatch?.service?.status?.status}
        edit={edit}
        readOnly={!attributes?.dispatch}
        onClick={() => {
          setEdit(!edit)
        }}
      />
      <Label>{t('labels.vehicle')}</Label>
      <Paragraph spacing="sm">{`${year} ${make} ${model} (${licensePlate})`}</Paragraph>
      <IncidentLocation.Vehicle location={attributes?.serviceLocations.vehicleLocation as LocationPayload} spacing="sm" />
      {children}
      {attributes?.breakdownOption && (
        <>
          <Label>{t('Breakdown Location')}</Label>
          <Paragraph spacing="sm">{attributes?.breakdownOption}</Paragraph>
        </>
      )}
      {attributes?.unsafe && (
        <>
          <Label>{t('Blocking traffic or unsafe')}</Label>
          <Paragraph spacing="sm">{attributes?.unsafe}</Paragraph>
        </>
      )}
      {attributes?.breakdownOption === BreakdownOptions.PARKINGGARAGE && attributes?.garageAccessible && (
        <>
          <Label>{t('Vehicle is garage accessible')}</Label>
          <Paragraph spacing="sm">{attributes?.garageAccessible}</Paragraph>
        </>
      )}
      {attributes?.breakdownOption !== BreakdownOptions.PARKINGGARAGE && attributes?.accessible && (
        <>
          <Label>{t('Vehicle is accessible')}</Label>
          <Paragraph spacing="sm">{attributes?.accessible}</Paragraph>
        </>
      )}
      {attributes?.notes && (
        <>
          <Label>{t('notesToProvider.label')}</Label>
          <Paragraph spacing="sm">{attributes?.notes}</Paragraph>
        </>
      )}
      <DispatchService dispatch={attributes?.dispatch} disableCancel={edit} />
      {caseId && <DispatchUlyService caseId={caseId} disableCancel={edit} />}
    </>
  )
}

export default SoftServiceEvent
