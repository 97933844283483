import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph, Label } from 'components/primitives'
import Margin from 'components/Margin'
import { useCallEvent } from 'hooks/events'
import { EventTypes } from 'types/events'
import { PartialVehicle, PartialVehicleWithStringYear } from 'types/urgently'

type EventVehicleProps = {
  vehicleInDispatch?: PartialVehicle | PartialVehicleWithStringYear
  vehicleConditionFromEvent?: string
  isTowDeliveryOnly?: boolean
}

function sanitizeVehicleLabel(vehicle: any) {
  if (!vehicle.label) return { ...vehicle, label: `VIN: ${vehicle.vin}` }

  return vehicle
}

export const EventVehicle = ({ vehicleInDispatch, vehicleConditionFromEvent, isTowDeliveryOnly = false }: EventVehicleProps) => {
  const towEvent = useCallEvent(EventTypes.TOW)
  const { t } = useTranslation()
  const { vehicle: recentEventVehicle, vehicleCondition } = towEvent?.event?.attributes || {}

  const vehicle = sanitizeVehicleLabel(recentEventVehicle || vehicleInDispatch || {})

  if (isTowDeliveryOnly) return null

  const condition = vehicleCondition || vehicleConditionFromEvent
  return (
    <>
      <Margin spacing="md">
        <Label>{t('labels.vehicle')}</Label>
        <Paragraph>{vehicle?.label}</Paragraph>
      </Margin>
      {condition && (
        <Margin spacing="md">
          <Label>{t('labels.vehicleCondition')}</Label>
          <Paragraph>{condition}</Paragraph>
        </Margin>
      )}
    </>
  )
}

export default EventVehicle
