import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray, isNil } from 'lodash-es'
import { Label, Paragraph, Heading } from 'components/primitives'
import Margin from 'components/Margin'
import DispatchService from 'components/Dispatch/DispatchService'
import { YesNoTriggers } from 'components/Input'
import { LocationLabels } from 'components/Input/Location'
import SmartAssignment from 'components/SmartAssignment'
import { isLocation, isParticipant } from 'utils/typeChecks'
import IncidentLocation from 'components/IncidentLocation'
import { ExchangeBranchDisplay } from 'components/Exchange'
import { DispatchHeader } from 'components/Dispatch'
import useAAADispatch from 'hooks/aaa/useAAADispatch'
import TowRideAlongOrThirdParty from '../Update/TowRideAlongOrThirdParty'
import TowDeliveryOnly from '../Update/TowDeliveryOnly'
import ReverseTwoWayTow from '../Update/ReverseTwoWayTow'
import NoExchangeTow from '../Update/NoExchangeTow'
import TowServiceLocations from '../TowEvents/TowServiceLocations'
import UpdateTowForm from '../Update/UpdateTowForm'
import EventVehicle from 'components/EventVehicle'
import DispatchUlyService from 'components/Dispatch/DispatchUlyService'
import { BreakdownOptions } from 'forms/CallForm/BreakdownDetails/BreakdownRadio'
import { DynamoEvents } from 'types/events'
import { LocationPayload, LocationState } from 'types/location'
import { Participant } from 'types/global'

export default function TowExchangeEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const [edit, setEdit] = React.useState<boolean>(false)
  const {
    serviceCase,
    dispatch,
    unattended,
    keysLocation,
    keysLocationOther,
    notes,
    dispatchRentalNotes,
    towDirection,
    network,
    breakdownOption,
    unsafe,
    accessible,
    garageAccessible,
  } = event?.attributes

  const callKey = isArray(dispatch) ? dispatch?.[0]?.callKey : null
  const { dispatch: aaaDispatch, loading: aaaLoading } = useAAADispatch(callKey)
  const caseId = serviceCase ? serviceCase?.caseId : null

  if (edit) {
    return (
      <>
        <DispatchHeader
          title={t('labels.towExchangeRequest')}
          dispatchStatus={aaaDispatch?.service?.status?.status}
          edit={edit}
          onClick={() => {
            setEdit(!edit)
          }}
          isUnattended={unattended}
        />
        <UpdateTowForm dispatchEvent={event} onUpdated={() => setEdit(false)}>
          {/* No editing for legacy tow events with 4 `serviceLocations` */}
          {Object.entries(event?.attributes.serviceLocations as LocationState).length === 3 ? (
            <ReverseTwoWayTow dispatchEvent={event} />
          ) : towDirection === 'branch' ? (
            <>{network ? <NoExchangeTow dispatchEvent={event} /> : <TowDeliveryOnly dispatchEvent={event} />}</>
          ) : (
            <TowRideAlongOrThirdParty dispatchEvent={event} />
          )}
        </UpdateTowForm>
      </>
    )
  }

  if (callKey && aaaLoading) {
    return (
      <Margin spacing="sm">
        <Heading as="h4">{t(`AAA Service`)}</Heading>
        <Label>{t('Retrieving Details...')}</Label>
      </Margin>
    )
  }

  if (aaaDispatch) {
    return (
      <>
        <DispatchHeader
          title={t('labels.towExchangeRequest')}
          dispatchStatus={aaaDispatch?.service?.status?.status}
          edit={edit}
          onClick={() => {
            setEdit(!edit)
          }}
          isUnattended={unattended}
        />
        <EventVehicle
          vehicleInDispatch={event.attributes?.vehicle}
          vehicleConditionFromEvent={event.attributes?.vehicleCondition}
          // Check for a specific 2 location dispatch.
          isTowDeliveryOnly={Object.keys(event.attributes.serviceLocations).length === 2}
        />
        {towDirection === 'branch' ? <TowServiceLocations {...event} /> : <DirectionVehicleEvent {...event} />}
        <Label>{t('Breakdown Location')}</Label>
        <Paragraph spacing="sm">{breakdownOption}</Paragraph>
        {unsafe && (
          <>
            <Label>{t('Blocking traffic or unsafe')}</Label>
            <Paragraph spacing="sm">{unsafe}</Paragraph>
          </>
        )}
        {breakdownOption === BreakdownOptions.PARKINGGARAGE && garageAccessible && (
          <>
            <Label>{t('Vehicle is garage accessible')}</Label>
            <Paragraph spacing="sm">{garageAccessible}</Paragraph>
          </>
        )}
        {breakdownOption !== BreakdownOptions.PARKINGGARAGE && accessible && (
          <>
            <Label>{t('Vehicle is accessible')}</Label>
            <Paragraph spacing="sm">{accessible}</Paragraph>
          </>
        )}
        {unattended && keysLocation && (
          <>
            <Label>{t('Where are the keys located?')}</Label>
            <Paragraph spacing="md">{keysLocationOther || keysLocation.label}</Paragraph>
          </>
        )}
        {dispatchRentalNotes && (
          <>
            <Label>{t('notesToProvider.branch')}</Label>
            <Paragraph spacing="md">{dispatchRentalNotes}</Paragraph>
          </>
        )}
        <Label>{`${t('notesToProvider.label')}*`}</Label>
        <Paragraph spacing="md">{notes}</Paragraph>
        {dispatch && <DispatchService dispatch={dispatch} />}
      </>
    )
  }

  if (caseId) {
    return (
      <>
        <DispatchHeader ulyHeader title={t('labels.towExchangeRequest')} isUnattended={unattended} />
        <EventVehicle
          vehicleInDispatch={event.attributes?.vehicle}
          vehicleConditionFromEvent={event.attributes?.vehicleCondition}
        />
        {towDirection === 'branch' ? <TowServiceLocations {...event} /> : <DirectionVehicleEvent {...event} />}
        {unattended && keysLocation && (
          <>
            <Label>{t('Where are the keys located?')}</Label>
            <Paragraph spacing="md">{keysLocationOther || keysLocation.label}</Paragraph>
          </>
        )}
        {dispatchRentalNotes && (
          <>
            <Label>{t('notesToProvider.branch')}</Label>
            <Paragraph spacing="md">{dispatchRentalNotes}</Paragraph>
          </>
        )}
        <Label>{`${t('notesToProvider.label')}*`}</Label>
        <Paragraph spacing="md">{notes}</Paragraph>
        {serviceCase && caseId && <DispatchUlyService caseId={caseId} />}
      </>
    )
  }

  return null
}
function DirectionVehicleEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const agentEID = event?.audit[0].eId
  const {
    serviceLocations,
    branch,
    customerWithVehicle,
    callerLocation,
    rideAlong,
    customerSupplied,
    network,
    exchangeNotes,
    vehicleAccommodationSelect,
    accommodationNotes,
    disabledVehicle,
    bypassReason,
  } = event?.attributes
  const isWithVehicle = customerWithVehicle === YesNoTriggers.YES
  return (
    <>
      <Label>{t('labels.customersTransportationMethod')}</Label>
      <Paragraph spacing="md">
        {customerSupplied ? t('Customer Supplied') : rideAlong ? t('Ride Along') : t('Third Party Transportation')}
      </Paragraph>
      <Margin spacing="md">
        {event?.attributes?.serviceLocations &&
          Object.values(serviceLocations as LocationState).map((location: LocationPayload | null, i: number) => {
            const isLast = i === Object.values(serviceLocations).length - 1

            if (isLocation(location)) {
              const label = LocationLabels[location.serviceLocationType]
              return (
                <React.Fragment key={i}>
                  <IncidentLocation label={t(label)} location={location} spacing="md" />
                </React.Fragment>
              )
            }

            if (isParticipant(location)) {
              const participantLocation = location as Participant | null

              if (isNil(participantLocation)) return null
              return (
                <Margin key={i} spacing={isLast ? 'none' : 'md'}>
                  <Label>{t('labels.disabledVehicleDropoff')}</Label>
                  <SmartAssignment.Participant participant={participantLocation} network={network!.value} showTooltip={false} />
                </Margin>
              )
            }

            return null
          })}
      </Margin>
      {rideAlong === false && (
        <>
          <IncidentLocation label={t('labels.callerLocation')} location={callerLocation} spacing="md">
            {isWithVehicle && <Paragraph>{t('Customer is with disabled vehicle')}</Paragraph>}
          </IncidentLocation>
        </>
      )}

      {branch && (
        <ExchangeBranchDisplay
          branch={branch}
          agentEID={agentEID}
          exchangeNotes={exchangeNotes}
          vehicleAccommodation={vehicleAccommodationSelect.label}
          accommodationNotes={accommodationNotes}
          disabledVehicle={disabledVehicle}
          bypassReason={bypassReason}
        />
      )}
    </>
  )
}
