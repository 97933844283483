import { TowExchange } from 'types/callstore'
import { transformExtendedAddress } from './aaa'
import { BranchAddress } from 'types/branch'
import { isNil } from 'lodash-es'
import { GeocodeAddress } from 'types/googleMaps'

/**
 * Only transforms the exchange branch information -- still requires the following
    rentalBrand
    rentalGPBR
    rentalStationId
    ticketNumber
    vinLast8
    agentEID
 */
// TODO: Cleanup: Either this payload comes from SOLR / GoogleMaps
// And it's being put into a value of either "branch" or in the serviceLocations
// DNV and plain TowExchange
export function createExchangeFromTowEvent(towEvent: TowExchange) {
  const exchangeBranch = towEvent.branch || towEvent.serviceLocations.exchangeLocation

  // Geocode payload
  const addressObject = (exchangeBranch?.address as GeocodeAddress)?.HouseNumber
    ? transformExtendedAddress(exchangeBranch?.address as GeocodeAddress)
    : undefined

  // SOLR payload
  const addressLines = (exchangeBranch?.address as BranchAddress)?.street_addresses?.[0]
    ? ((exchangeBranch?.address as BranchAddress)?.street_addresses[0] as string)
    : undefined

  const address =
    !isNil(addressObject) && addressLines // SOLR
      ? addressLines
      : !isNil(addressLines) && !!addressObject // Geocode
        ? `${addressObject.streetNumber} ${addressObject.street} ${addressObject.city} ${addressObject.state} ${addressObject.postalCode}${addressObject.country}`
        : ''

  return {
    exchangeBrand: exchangeBranch?.brand,
    exchangeGPBR: exchangeBranch?.additional_data.group_branch_number,
    peopleSoftId: exchangeBranch?.id,
    formattedPhone: exchangeBranch?.additional_data.formatted_phone,
    addressLines: address,
    formattedCityStateZip: exchangeBranch?.additional_data.formatted_city_state_zip,
    locationNameTranslation: exchangeBranch?.name,
    notes: towEvent.exchangeNotes,
    vehicleAccommodation: towEvent.vehicleAccommodationSelect.label,
    accommodationNotes: towEvent.accommodationNotes,
    disabledVehicle: towEvent.disabledVehicle,
    bypassReason: towEvent.bypassReason,
    stationId: exchangeBranch?.station_id,
    branchId: exchangeBranch?.id,
  }
}
