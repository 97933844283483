import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import AppRoutes from '../../routes'
import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Flex } from 'components/primitives'
import { callerDefault } from 'types/callstore'
import { selectTicket, selectTicketHistoryEvents } from 'redux/appStore'
import { GoogleMapProviders } from 'components/GoogleMaps'
import Loading from './Loading'
import Sidebar from './Sidebar'
import LeftSidebar from './LeftSidebar'
import RightSidebar from './RightSidebar'
import { HeroLayersProvider } from './HeroMap/Layers'
import { HeroMapWithGoogle } from './HeroMap/HeroMapWithGoogle'
import { SidebarPosition } from 'types/global'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocalQuickNotes, selectTicketError } from 'redux/appStore'
import { useMount } from 'hooks/utils'
import { updateFormState } from 'redux/slices/appSlice'
import { MarkerType } from 'types/googleMaps'
import { updateLocation } from 'redux/location/locationSlice'
import { useCallEvents } from 'hooks/events'
import { useTicketUrlTracker } from 'hooks/tickets'
import useTimerState from 'hooks/useTimerState'
import { DynamoEvents } from 'types/events'
import { getCountryOption } from 'hooks/events/caller/useCallerFormValues'

const TicketRoute = () => {
  const { ticketNumber } = useParams()

  if (!ticketNumber)
    return (
      <Routes>
        <Route element={<Navigate to={AppRoutes.TICKET_SEARCH} />} />
      </Routes>
    )
  return <Ticket ticketNumber={ticketNumber.toUpperCase()} />
}

const Ticket: React.FC<{ ticketNumber: string }> = ({ ticketNumber }) => {
  const dispatch = useDispatch()
  const currentTicket = useSelector(selectTicket)
  const currentTicketNumber = currentTicket?.ticketNumber
  const timerState = useTimerState()
  const error = useSelector(selectTicketError)
  const { callerName, countryCode, number, notes } = useSelector(selectLocalQuickNotes)
  const isFetchingNewTicket = useTicketUrlTracker(ticketNumber)

  useCallEvents()

  const events = useSelector(selectTicketHistoryEvents) || []

  React.useEffect(() => {
    if (currentTicketNumber && currentTicketNumber !== timerState.ticketNumber) {
      timerState.set({
        ticketNumber: currentTicketNumber,
        createdAt: new Date().toISOString(),
      })
    }
  }, [currentTicketNumber, ticketNumber, timerState])

  useMount(() => {
    dispatch(
      updateFormState({
        caller: {
          ...callerDefault,
          callerName,
          phone: { countryCode, number, countryOption: getCountryOption(countryCode.value) },
        },
        notes,
      }),
    )
  })

  React.useEffect(() => {
    function setRecentLocationData(events: DynamoEvents[]) {
      const recentEventWithLocations = events.find((event) => event.serviceLocations)

      if (recentEventWithLocations) {
        const serviceLocs = recentEventWithLocations.serviceLocations
        if (serviceLocs) {
          if (serviceLocs.callerLocation) {
            let callerLoc = serviceLocs.callerLocation
            dispatch(
              updateLocation({
                name: 'callerLocation',
                location: {
                  ...callerLoc,
                  serviceLocationType: MarkerType.CALLER,
                },
              }),
            )
          }
          if (serviceLocs.vehicleLocation) {
            let vehicleLoc = serviceLocs.vehicleLocation
            dispatch(
              updateLocation({
                name: 'vehicleLocation',
                location: {
                  ...vehicleLoc,
                  serviceLocationType: MarkerType.VEHICLE,
                },
              }),
            )
          }
          if (serviceLocs.exchangeLocation) {
            let exchangeLoc = serviceLocs.exchangeLocation
            dispatch(
              updateLocation({
                name: 'exchangeLocation',
                location: {
                  ...exchangeLoc,
                  serviceLocationType: MarkerType.EXCHANGE,
                },
              }),
            )
          }
          if (serviceLocs.vehicleDestinationLocation) {
            let vehicleDestinationLoc = serviceLocs.vehicleDestinationLocation
            dispatch(
              updateLocation({
                name: 'vehicleDestinationLocation',
                location: {
                  ...vehicleDestinationLoc,
                  serviceLocationType: MarkerType.VEHICLEDESTINATION,
                },
              }),
            )
          }
        }
      }
    }
    if (events?.length > 0) {
      setRecentLocationData(events)
    }
  }, [dispatch, events.length])

  const oldTicket = currentTicketNumber !== ticketNumber || isFetchingNewTicket
  return <Page ticketNumber={ticketNumber} hasError={Boolean(error)} oldTicket={oldTicket} />
}

function Page({ oldTicket, hasError, ticketNumber }: { oldTicket: boolean; hasError: boolean; ticketNumber: string }) {
  const ticket = useSelector(selectTicket)
  const isLoading = !ticket || oldTicket
  return (
    <AnimatePresence>
      {isLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0, position: 'absolute', height: '100%' }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Loading hasError={hasError} ticketNumber={ticketNumber} />
        </motion.div>
      ) : (
        <motion.div
          key="ticketPage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, width: 0 }}
          style={{ display: 'flex', flex: 1 }}
        >
          <GoogleMapProviders>
            <Flex style={{ flex: 1 }}>
              <LeftSidebar />
              <HeroLayersProvider>
                <Flex style={{ flex: 1, position: 'relative' }}>
                  {Object.values(SidebarPosition).map((side) => (
                    <Sidebar.Toggle key={side} side={side as SidebarPosition} />
                  ))}
                  <HeroMapWithGoogle />
                </Flex>
                <RightSidebar />
              </HeroLayersProvider>
            </Flex>
          </GoogleMapProviders>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default TicketRoute
