import * as React from 'react'
import Divider from 'components/Divider'
import { Flex, Heading } from 'components/primitives'
import { Branch, CarClassAvailability } from 'types/global'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import Margin from 'components/Margin'
import ExchangeMarker from '../ExchangeMarker'
import RequestExchange from './RequestExchange'
import { CarouselControl, CarouselControlProps } from 'components/Carousel'
import { pxToRem } from 'theme/utils'
import { ListItem } from 'components/List'
import BranchHoursTable from 'pages/Ticket/routes/Default/Details/Branch/BranchHoursTable'
import { useTranslation } from 'react-i18next'
import { mountainMeadow, shiraz } from '../../../theme/colors'
import { useLocalTimeZone, useMount } from '../../../hooks/utils'
import { parseInt } from 'lodash-es'
import useBranchHours from 'hooks/solr/useBranchHours'
import { updateLocation } from 'redux/location/locationSlice'
import { MarkerType } from 'types/googleMaps'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { updateFormState } from 'redux/slices/appSlice'

type Props = {
  name: string
  onSetBranch?: (branch: Branch) => void
  availability: CarClassAvailability
  isTowExchange: boolean
} & CarouselControlProps

const CarouselItem = ({ name, onSetBranch, availability, isTowExchange, ...carouselControls }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { exchange } = useSelector(selectFormsState) as any
  const { branchData } = availability
  const { timezone: localTimeZone } = useLocalTimeZone({
    lat: branchData.gps.latitude,
    lon: branchData.gps.longitude,
  })
  let date: any = new Date()
  let startDateForBranchHours = date.toISOString().split('T')
  startDateForBranchHours = startDateForBranchHours[0]
  let endDateForBranchHours: any = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')
  endDateForBranchHours = endDateForBranchHours[0]

  const { data: branchHourData, loading: branchHoursLoading } = useBranchHours(
    branchData.id,
    startDateForBranchHours,
    endDateForBranchHours,
  )

  useMount(() => {
    dispatch(updateFormState({ exchange: { ...exchange, isFirstRecommendedOption: carouselControls.isFirst } }))
  })

  let branchWithinAnHoursOfClosing = false
  let time: string = ''
  let timeSplit
  let clock
  let amPm
  let timeZone

  React.useEffect(() => {
    if (branchData.gps.latitude && branchData.gps.longitude) {
      const [streetNumber, street] = branchData.address.street_addresses[0].split(' ')
      const branchAddress = {
        city: branchData.address.city,
        country: branchData.address.country_code,
        countryShortCode: branchData.address.country_code,
        postalCode: branchData.address.postal,
        state: branchData.address.country_subdivision_code,
        street,
        streetNumber,
      }
      dispatch(
        updateLocation({
          name: 'exchangeLocation',
          location: {
            ...branchData,
            latitude: branchData.gps.latitude,
            longitude: branchData.gps.longitude,
            address: branchAddress,
            addressDescription: branchData.name,
            serviceLocationType: MarkerType.EXCHANGE,
          },
        }),
      )
    }
  }, [branchData, dispatch])

  if (!branchData) return null

  let open24hours: boolean = false
  let openCloseTimesByDay: any = null
  let timeColor: string = shiraz
  let localHour: number = -1
  let localMinutes: number = -1

  if (localTimeZone && branchHourData) {
    open24hours = branchHourData[0]?.standard.open24Hours

    if (branchHourData?.length) {
      openCloseTimesByDay = branchHourData.map((hoursForDay) => {
        let todaysHours: any = Object.assign({
          day: null,
          opHours: [],
        })
        // eslint-disable-next-line array-callback-return
        hoursForDay.standard.hours.map((hours, index) => {
          todaysHours.day = hoursForDay.day
          let hoursObject: any = Object.assign({
            openHour: null,
            openMinutes: null,
            closeHour: null,
            closeMinutes: null,
          })
          let openTime = hours.open.split(':')
          let closeTime = hours.close.split(':')
          hoursObject.openHour = parseInt(openTime[0])
          hoursObject.openMinutes = parseInt(openTime[1])
          hoursObject.closeHour = parseInt(closeTime[0])
          hoursObject.closeMinutes = parseInt(closeTime[1])
          todaysHours.opHours.push(hoursObject)
        })
        return todaysHours
      })
    }

    time = date.toLocaleTimeString('en-US', {
      timeZone: localTimeZone,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })
    timeSplit = time.split(' ')
    clock = timeSplit[0]
    amPm = timeSplit[1]
    timeZone = timeSplit[2]
    timeSplit = time.split(':')
    localHour = parseInt(timeSplit[0])
    if (amPm === 'PM' && localHour !== 12) localHour = localHour + 12
    timeSplit = timeSplit[1].split(' ')
    localMinutes = parseInt(timeSplit[0])

    if (open24hours) {
      timeColor = mountainMeadow
    } else {
      // eslint-disable-next-line array-callback-return
      openCloseTimesByDay[0].opHours.map((hours: any) => {
        if (hours.openHour < localHour && localHour < hours.closeHour!) {
          timeColor = mountainMeadow
        } else if (localHour === hours.openHour) {
          if (localMinutes >= hours.openMinutes!) {
            timeColor = mountainMeadow
          }
        } else if (localHour === hours.closeHour) {
          if (localMinutes < hours.closeMinutes!) {
            timeColor = mountainMeadow
          }
        }

        if (
          localHour < hours.closeHour! &&
          !(localHour < hours.closeHour! - 1) &&
          hours.closeHour !== 23 &&
          hours.closeMinutes !== 59
        ) {
          branchWithinAnHoursOfClosing = true
          timeColor = shiraz
        } else if (localHour === hours.closeHour! && hours.closeHour !== 23 && hours.closeMinutes !== 59) {
          if (localMinutes <= hours.closeMinutes!) {
            branchWithinAnHoursOfClosing = true
            timeColor = shiraz
          }
        }

        if (hours.closeHour === 23 && hours.closeMinutes === 59 && localHour === 23) {
          let nextDayHours = openCloseTimesByDay[1].opHours[0]
          if (nextDayHours.openHour === 0 && nextDayHours.openMinutes === 0) {
            if (nextDayHours.closeHour >= 1) {
              timeColor = mountainMeadow
            } else if (nextDayHours === 0) {
              if (localMinutes < nextDayHours.closeMinutes) {
                branchWithinAnHoursOfClosing = true
                timeColor = shiraz
              }
            }
          }
        }
      })
    }
  }

  return (
    <>
      <Margin use={Flex} spacing="md" style={{ justifyContent: 'space-between' }}>
        <Flex style={{ flexDirection: 'column' }}>
          <Heading as="h5">{branchData.brand + ' - ' + branchData.additional_data.group_branch_number}</Heading>
          <Heading as="h6">{branchData.address.street_addresses[0]}</Heading>
          <Heading as="h6">{branchData.name}</Heading>
        </Flex>
        <CarouselControl showBypassModals={true} {...carouselControls} />
      </Margin>
      {branchHourData && time && !branchHoursLoading && (
        <Margin spacing="md">
          <Flex style={{ alignItems: 'center', margin: `0 0 ${pxToRem(8)}` }}>
            <ListItem.Text>
              {t('date.branchLocalTime')}
              {clock && amPm && timeZone ? (
                <span style={{ color: timeColor, fontWeight: 'bold' }}>{' ' + clock + ' ' + amPm + ' (' + timeZone + ')'}</span>
              ) : null}
            </ListItem.Text>
          </Flex>
          {branchWithinAnHoursOfClosing ? (
            <ListItem.Text style={{ color: shiraz, fontWeight: 'bold', textAlign: 'center' }}>
              {t('date.branchClosingSoon')}
            </ListItem.Text>
          ) : null}
          <BranchHoursTable
            hours={branchHourData}
            statusColor={timeColor.toString()}
            localHour={localHour}
            localMinutes={localMinutes}
          />
        </Margin>
      )}
      <Divider />
      <GoogleFeatureGroup>
        <RequestExchange
          name={name}
          branch={branchData}
          onSetBranch={onSetBranch}
          isFirst={carouselControls.isFirst}
          isTowExchange={isTowExchange}
        />
        <ExchangeMarker branchData={branchData} />
      </GoogleFeatureGroup>
    </>
  )
}

export default CarouselItem
