import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Assignment, AssignmentTypes, AssignmentOperations, SmartAssignment } from 'types/entegral'
import { BranchInfoFragment, Vehicle } from 'types/global'
import { AssignmentNetworks } from 'types/global'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { useSelector, useDispatch } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import {
  setSmartAssignmentAssignment,
  setSmartAssignmentParticipant,
  setSmartAssignmentCustomDropoffDestination,
} from 'redux/smartAssignment/smartAssignmentSlice'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { Paragraph } from 'components/primitives'
import { ErrorMessage } from 'components/fields/Error'
import { Props as ResultProps } from './Results'
import { useServiceProxy } from 'hooks/kong'
import { mapEntegralBusinessHours } from 'utils/entegral'

type Props = {
  title: string
  vehicle: Vehicle
  network: AssignmentNetworks
} & Pick<ResultProps, 'onSetAssignment'>

export const Create: React.FC<Props> = ({ title, network, vehicle, onSetAssignment }) => {
  const { t } = useTranslation()
  const ticket = useSelector(selectTicket)
  const ticketNumber = ticket.ticketNumber
  const pickupBranch = ticket.pickupInfo.branch as BranchInfoFragment
  const { addressLines, formattedCityStateZip, formattedPhone, groupBranchNumber } = pickupBranch
  const dispatch = useDispatch()
  const [failed, setFailed] = React.useState<Boolean>(false)
  const proxy = useServiceProxy()
  const [loading, setLoading] = React.useState<boolean>(false)
  const vehicleLocation = useVehicleLocation()
  const callerLocation = useCallerLocation()
  const disabled = !network || !vehicle || !vehicleLocation

  const filterType = () => {
    if (network === AssignmentNetworks.SERVICE) {
      return 'serviceCenterNetwork:Yes'
    } else if (network === AssignmentNetworks.TIRE) {
      return 'maintenanceNetwork:Yes'
    } else if (network === AssignmentNetworks.BODY) {
      return 'enterpriseNetwork:Yes'
    }
    return 'enterpriseNetwork:Yes'
  }

  const filterValue = filterType()

  const onClick = async () => {
    try {
      setLoading(true)
      dispatch(setSmartAssignmentParticipant(null))
      dispatch(setSmartAssignmentCustomDropoffDestination(null))
      // UI Requires one of these locations to be present before being able to search for a network
      const location = (vehicleLocation || callerLocation)!

      const { color, make, model, year, vin } = vehicle ?? {}

      const assignment: Assignment = {
        assignmentType: AssignmentTypes.VEHICLE,
        assignmentOperation: AssignmentOperations.NEW,
        assignmentDetails: {
          vehicle: {
            vin,
            color,
            make,
            model,
            year,
            currentLocation: {
              latitude: location.latitude!,
              longitude: location.longitude!,
            },
          },
          additionalInformation: [
            {
              name: 'rentalAgreementNumber',
              value: ticketNumber,
            },
            {
              name: 'groupBranchNumber',
              value: groupBranchNumber,
            },
            {
              name: 'groupBranchAddress',
              value: `${addressLines && addressLines[0]}, ${formattedCityStateZip}`,
            },
            {
              name: 'groupBranchPhone',
              value: formattedPhone,
            },
            {
              name: 'radius',
              value: 200,
            },
            {
              name: 'radius-units',
              value: 'MILES',
            },
            {
              name: 'enableSmartFilters',
              value: 'false',
            },
            {
              name: 'filters',
              value: filterValue,
            },
          ],
          searchAddress: {
            latitude: location.latitude!,
            longitude: location.longitude!,
          },
        },
      }

      const params = { expanded: 'participants' }

      const queryString = new URLSearchParams(params).toString()

      const { data } = await proxy<SmartAssignment>(
        'post',
        `/serviceproxy/entegral/assignments?${queryString}`,
        {},
        { assignment },
      )

      if (!data) throw new Error('Unable to create network assignment')

      const filteredSmartAssignments = {
        ...data,
        participants: data.participants.filter(
          (participant) => participant && participant.entity.address?.country === location.address.countryShortCode,
        ),
      }

      const dataWithBusinessHours = mapEntegralBusinessHours(filteredSmartAssignments)

      setLoading(false)
      dispatch(setSmartAssignmentAssignment(dataWithBusinessHours))
    } catch (e) {
      console.error(e)
      setLoading(false)
      setFailed(true)
    }
  }

  return (
    <Margin spacing="md">
      <Button.Primary loading={loading} disabled={disabled} onClick={onClick} style={{ width: '100%' }}>
        {title}
      </Button.Primary>
      {failed && (
        <>
          <Margin spacing="md">
            <Paragraph as={ErrorMessage}>{t('network.createError')}</Paragraph>
          </Margin>
        </>
      )}
    </Margin>
  )
}

export default Create
