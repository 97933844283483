import * as React from 'react'
import { Form } from 'react-final-form'
import { EventTypes } from 'types/events'
import { LdrClaim } from 'types/callstore'
import Divider from 'components/Divider'
import { StepNavigation } from 'components/Step'
import { useCreateServiceEvent } from 'hooks/events'
import LDRFields from '../LDRFields'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectFormsState, selectTicket } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { MailData, Recipients, getEmailRecipients } from '../../../../hooks/emails'
import { useServiceProxy } from '../../../../hooks/kong'
import Handlebars from 'handlebars'
import { config } from '../../../../config'
import { mergeWith } from 'lodash-es'
import { formatErrors, scrollErrorIntoView } from 'components/FormManager/Submit'
import { ErrorMessage } from 'components/fields/Error'
import { BranchInfoFragment } from 'types/branch'
import { TransformedVehicle } from 'types/ticket'

export function LDRConfirmation() {
  const { ldr, caller } = useSelector(selectFormsState) as any
  const dispatch = useDispatch()
  const createServiceEvent = useCreateServiceEvent<Partial<LdrClaim>>({
    eventType: EventTypes.LDR,
  })
  const serviceProxy = useServiceProxy()
  const currentUser = useSelector(selectCurrentUser)
  const { ticketNumber, pickupInfo } = useSelector(selectTicket)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  async function createLDREvent() {
    try {
      setError(null)
      setLoading(true)
      await caller.submit()
      const callerStateValues = caller.getState()
      const callerState = mergeWith({}, callerStateValues, caller)

      if (!callerState.valid) {
        let errorArray = [...Object.keys(callerState.errors as any)]
        if (errorArray.includes('drivers')) {
          if (callerState.values.selectedCaller === 'account' || callerState.values.selectedCaller === 'other') {
            errorArray = errorArray.filter((element) => element !== 'drivers')
          }
        }

        if (errorArray.length) {
          setError(formatErrors(errorArray))
          scrollErrorIntoView(errorArray[0], false)
          setLoading(false)
          return
        }
      }

      const { claim } = ldr
      const { noReplyExchangeEmailsCsv, rentalAgreementUrn } = config
      const event = await createServiceEvent(
        { claim, claimNumber: '' },
        undefined,
        claim.vehicle as unknown as TransformedVehicle,
      )
      const ldrEventId = event.data.eventIdentifier

      if (ldrEventId) {
        dispatch(setCurrentEvent({ eventType: EventTypes.LDR, newEvent: event.data }))
      }

      const pickupBranch = pickupInfo.branch as BranchInfoFragment
      // Send LDR Email Email
      const htmlFile = require('emails/ldrEmail.html').default
      const compileHandlebars = Handlebars.compile(htmlFile)
      const emailData = {
        ...ldr,
        peopleSoftId: pickupBranch.peopleSoftId,
        ticketNumber,
        agentName: currentUser?.name,
        agentEID: currentUser?.nameID,
      }
      // TODO: test for firstParty and thirdParty details for the email data.
      //  how does this data get generated? Is it no longer valid? test in develop
      return await serviceProxy<MailData>(
        'post',
        `/serviceproxy/email`,
        { 'ehi-device-location-id': emailData.peopleSoftId },
        {
          type: 'HTML',
          context: rentalAgreementUrn + emailData.ticketNumber,
          sender: {
            address: noReplyExchangeEmailsCsv,
            displayName: 'Roadside Assistance',
          },
          subject: 'LDR',
          message: compileHandlebars(emailData),
          to: [...getEmailRecipients(Recipients.LDR(emailData))],
        },
      )
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <LDRFields {...ldr} />
      <Divider />
      <Form onSubmit={() => {}}>
        {() => (
          <>
            <StepNavigation onSubmit={createLDREvent} />
            {error && <ErrorMessage style={{ display: 'block', marginTop: 8 }}>{error}</ErrorMessage>}
          </>
        )}
      </Form>
    </>
  )
}

export default LDRConfirmation
