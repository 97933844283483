import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'components/primitives'
import { useExchangeLocation } from 'hooks/redux/useLocations'
import Margin from 'components/Margin'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import { useFieldValue } from 'hooks/utils'
import ExchangeMarker from './ExchangeMarker/ExchangeMarker'

type Props = {
  name: string
}

const ExchangeBranch = ({ name }: Props) => {
  const { t } = useTranslation()
  const exchangeLocation = useExchangeLocation()
  const agentEID = useFieldValue('agentEID')
  let branch = useFieldValue(name)
  if (!branch) return null
  if (branch?.branch) branch = branch.branch

  const locationNameTranslation = branch?.name
  const brand = branch?.brand
  const formattedPhone = branch?.additional_data?.formatted_phone
  const formattedCityStateZip = branch?.additional_data?.formatted_city_state_zip
  const addressLines = branch?.address?.street_addresses?.[0]

  return (
    <>
      {!exchangeLocation && (
        <GoogleFeatureGroup>
          <ExchangeMarker branchData={branch} />
        </GoogleFeatureGroup>
      )}
      {agentEID && (
        <>
          <Label>{t('Agent EID')}</Label>
          <Margin spacing="md">{agentEID}</Margin>
        </>
      )}
      <Label>{t('labels.exchangeBranch')}</Label>
      <Margin spacing="md">
        <Margin spacing="sm">
          {brand} - {locationNameTranslation}
        </Margin>
        <Margin spacing="sm">{formattedPhone}</Margin>
        <Margin spacing="sm">
          {addressLines}
          <div>{formattedCityStateZip}</div>
        </Margin>
      </Margin>
    </>
  )
}

export default ExchangeBranch
