import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Label } from 'components/primitives'
import { Form, Field as FormField } from 'react-final-form'
import { Brands } from 'types/global'
import Button from 'components/Button'
import Section from 'components/Section'
import Margin from 'components/Margin'
import LocationSearch from './LocationSearch'
import StatusDisplay from './StatusDisplay'
import TransferStatus from './TransferStatus'
import BrandFields from './BrandFields'
import BranchSearch from './BranchSearch'
import BranchDetails from './BranchSearch/BranchDetails'
import * as Ariakit from '@ariakit/react'

function TransferContainer() {
  const { t } = useTranslation()
  const container = Ariakit.useDisclosureContext()

  return (
    <TransferStatus>
      <Section
        title={t('billingTransfer')}
        action={<Button onClick={container?.hide} style={{ padding: 0 }} iconBefore={MdClose} />}
        style={{
          width: 340,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Section.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxHeight: '100%',
          }}
        >
          <div className="pendo-billingtransfercalls-ticket"></div>
          <Label spacing="md">{t('Search for a branch to transfer the caller to.')}</Label>
          <Form initialValues={{ brand: Brands.ENTERPRISE, location: null }} subscription={{ values: true }} onSubmit={() => {}}>
            {({ handleSubmit, values }) => {
              const { location, brand } = values
              return (
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  style={{
                    maxHeight: '100%',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  <Margin spacing="md">
                    <FormField name="location" component={LocationSearch} />
                  </Margin>
                  <BrandFields />
                  {location &&
                    (location.isBranch ? (
                      <BranchDetails branch={location} />
                    ) : (
                      <BranchSearch lat={location.latitude} lon={location.longitude} brand={brand} />
                    ))}
                  {!location && <StatusDisplay />}
                </form>
              )
            }}
          </Form>
        </Section.Body>
      </Section>
    </TransferStatus>
  )
}
export default TransferContainer
