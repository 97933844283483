import { FormApi } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label } from 'components/primitives'
import { CallTypes } from 'types/callstore'
import Complete from 'components/Complete'
import * as Yup from 'yup'
import { EventTypes } from 'types/events'
import { Condition } from 'components/fields'
import { Form } from 'components/FormManager'
import { TicketCharges } from 'types/ticket'
import Location from 'components/Input/Location'
import { useReduxCallStoreActions, useCallEvent } from 'hooks/events'
import Margin from 'components/Margin'
import Divider from 'components/Divider'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import Waivers from 'components/Waivers/Waivers'
import { WorkflowAction } from 'components/Workflow'
import WinchEvent from './WinchEvent'
import { YY, NN, NY, WinchDispatch } from './WinchControls'
import { VehicleSelect } from 'components/Select'
import { isRequired } from 'utils/validators'
import useCreateServiceEvent from '../../../hooks/events/useCreateServiceEvent'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.WINCH]: FormApi
  }
}

export default function WinchService() {
  const { t } = useTranslation()
  const { winch } = useSelector(selectFormsState) as any
  const { update } = useReduxCallStoreActions()
  const winchEvent = useCallEvent(EventTypes.WINCH)
  const towEvent = useCallEvent(EventTypes.TOW)
  const ldrEvent = useCallEvent(EventTypes.LDR)

  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.WINCH } || null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const submitWinch = async () => {
      const event = await createServiceEvent({
        ...winch,
      })
      dispatch(setCurrentEvent({ eventType: EventTypes.WINCH, newEvent: event.data }))
    }
    // This is for automatically submitting the Winch workflow form IF tow and ldr events already exist
    if (towEvent && ldrEvent && !winchEvent) {
      submitWinch()
    }
  }, [ldrEvent, towEvent])

  const needsLDRSuggestion = !(
    winchEvent?.event?.attributes?.okayToDrive === 'no' && winchEvent?.event?.attributes?.moreThan50ft === 'no'
  )

  if (winchEvent && winchEvent.event)
    return (
      <>
        <Margin spacing="md">
          <WinchEvent {...winchEvent.event} />
        </Margin>
        {needsLDRSuggestion ? (
          <>
            <Margin spacing="md">
              <Heading as="h5" spacing="md">
                {t('labels.suggestion')}
              </Heading>
            </Margin>
            <Margin spacing="md">
              <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} />
            </Margin>
          </>
        ) : null}
      </>
    )

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('winch.heading')}
      </Heading>

      <Margin spacing="md">
        <Label> {t('Fees')}</Label>
        <Waivers charge={TicketCharges.OTHER_CHARGE} />
      </Margin>
      <Form name={CallTypes.WINCH} schema={winchSchema} initialValues={winch} autoSaveSync={(winch) => update({ winch })}>
        <Margin spacing="md">
          <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
          <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
        </Margin>
        <Margin spacing="md">
          <Location.Vehicle name="serviceLocations.vehicleLocation" />
          <Input.Hidden name="serviceLocations.vehicleLocation" validate={isRequired('Vehicle location is required')} />
        </Margin>
        <Divider />

        <YesNoRadio name="okayToDrive" label={t('winch.okayToDrive')} />
        <YesNoRadio name="moreThan50ft" label={t('winch.moreThan50ft')} />

        <Condition when="okayToDrive" is={YesNoTriggers.YES}>
          <Condition when="moreThan50ft" is={YesNoTriggers.YES}>
            <Margin spacing="md">
              <YY />
            </Margin>
            <Margin spacing="md">
              <Complete eventType={EventTypes.WINCH} />
            </Margin>
          </Condition>
          <Condition when="moreThan50ft" is={YesNoTriggers.NO}>
            <Margin spacing="md">
              <WinchDispatch />
            </Margin>
          </Condition>
        </Condition>
        <Condition when="okayToDrive" is={YesNoTriggers.NO}>
          <Condition when="moreThan50ft" is={YesNoTriggers.YES}>
            <Margin spacing="md">
              <NY />
            </Margin>
            <Margin spacing="md">
              <Complete eventType={EventTypes.WINCH} />
            </Margin>
          </Condition>
          <Condition when="moreThan50ft" is={YesNoTriggers.NO}>
            <Margin spacing="md">
              <NN />
            </Margin>
          </Condition>
        </Condition>
      </Form>
    </>
  )
}

export const winchSchema = Yup.object({
  okayToDrive: Yup.string().nullable().required('Vehicle Drivability field is required'),
  moreThan50ft: Yup.string().nullable().required('50ft From Paved Surface field is required'),
})
