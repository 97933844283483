import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CaseServiceCall } from 'types/urgently'
import { StandardServiceCall } from 'types/aaa'

type RecentDispatchState = {
  AAADispatches: StandardServiceCall[]
  ULYDispatches: CaseServiceCall[]
}

const initialState: RecentDispatchState = {
  AAADispatches: new Array<StandardServiceCall>(),
  ULYDispatches: new Array<CaseServiceCall>(),
}

const recentDispatchSlice = createSlice({
  name: 'RecentDispatches',
  initialState,
  reducers: {
    addRecentAAADispatch: (state: RecentDispatchState, action: PayloadAction<StandardServiceCall>) => {
      state.AAADispatches.push(action.payload)
    },
    removeRecentAAADispatch: (state: RecentDispatchState, action: PayloadAction<string>) => {
      state.AAADispatches = state.AAADispatches.filter((dispatch) => dispatch.callKey !== action.payload)
    },
    addRecentULYDispatch: (state: RecentDispatchState, action: PayloadAction<CaseServiceCall>) => {
      state.ULYDispatches.push(action.payload)
    },
    removeRecentULYDispatch: (state: RecentDispatchState, action: PayloadAction<string>) => {
      state.ULYDispatches = state.ULYDispatches.filter((dispatch) => dispatch.case.id !== action.payload)
    },
    resetRecentDispatches: (state: RecentDispatchState) => {
      state = initialState
      return state
    },
  },
})

export const {
  addRecentAAADispatch,
  removeRecentAAADispatch,
  addRecentULYDispatch,
  removeRecentULYDispatch,
  resetRecentDispatches,
} = recentDispatchSlice.actions

export default recentDispatchSlice.reducer
