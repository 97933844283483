import * as React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { MdClear } from 'react-icons/md'
import AutoSave from 'components/AutoSave'
import Button from 'components/Button'
import Input from 'components/Input'
import { useDispatch } from 'react-redux'
import { updateQuickNotes } from 'redux/slices/appSlice'
import { getInitialAppState } from 'redux/helpers'
import { QuickNotesState, QuickNotes } from 'types/global'

export const QuickNoteForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const setQuickNotes = (input: QuickNotesState) => dispatch(updateQuickNotes(input))

  return (
    <Form onSubmit={() => {}}>
      {({ form }) => (
        <form autoComplete="off">
          <AutoSave.Sync<QuickNotes> save={setQuickNotes} />
          <Input label={t('Caller Name')} name="callerName" defaultValue="" />
          <Input.Phone label={t('Callback Number')} countryCodeName="countryCode" numberName="number" />
          <Input.Textarea label={t('Notes')} name="notes" />

          <Button
            type="reset"
            onClick={() => {
              form.reset()
              setQuickNotes(getInitialAppState().quickNotes)
            }}
            iconBefore={MdClear}
          >
            {t('Reset')}
          </Button>
        </form>
      )}
    </Form>
  )
}

export default QuickNoteForm
