import { combineReducers, configureStore, createSelector } from '@reduxjs/toolkit'
import { EventTypes } from 'types/events'
import appSlice from './slices/appSlice'
import configSlice from './slices/configSlice'
import mapSlice from './map/mapSlice'
import exchangeSearchSlice from './exchangeSearch/exchangeSearchSlice'
import fleetVehicleSlice from './fleetVehicle/fleetVehicleSlice'
import connectedSearchSlice from './connectedSearch/connectedSearchSlice'
import locationSlice from './location/locationSlice'
import ticketSlice from './ticket/ticketSlice'
import tempMarkerSlice from './tempMarker/tempMarkerSlice'
import smartAssignmentSlice from './smartAssignment/smartAssignmentSlice'
import currentEventSlice from './currentEvent/currentEventSlice'
import callHistorySlice from './callHistory/callHistorySlice'
import recentDispatchSlice from './recentDispatch/recentDispatchSlice'
import { FormApi } from 'final-form'
import { FormName, Forms } from 'types/form'
import { LocationState } from 'types/location'
import { TransformedTicket } from 'types/ticket'

const reducer = combineReducers({
  appSlice,
  configSlice,
  callHistorySlice,
  connectedSearchSlice,
  currentEventSlice,
  exchangeSearchSlice,
  fleetVehicleSlice,
  locationSlice,
  mapSlice,
  smartAssignmentSlice,
  tempMarkerSlice,
  ticketSlice,
  recentDispatchSlice,
})

export type ReduxState = ReturnType<typeof reducer>

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    // Ignore write errors;
  }
}

const peristedState = loadState()

/**
 * Creates a new store. Avoiding singletons here so tests are cleaner.
 * @returns A new instance of a HERO store.
 */
export const getStore = () => {
  const store = configureStore({
    reducer,
    preloadedState: peristedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
  store.subscribe(() => {
    saveState(store.getState())
  })

  return store
}

// AppSlice
export const selectSidebarState = (state: ReduxState) => (side: 'left' | 'right') => state.appSlice.sidebar[side]
export const selectFormsState = (state: ReduxState) => state.appSlice.forms
export const selectTabsState = (state: ReduxState) => state.appSlice.tabs
export const selectLocalQuickNotes = (state: ReduxState) => state.appSlice.quickNotes
export const selectCustomVinInput = (state: ReduxState) => state.appSlice.customVin.vinInput

// ConfigSlice
export const selectCurrentUser = (state: ReduxState) => state.configSlice.auth.user

// TODO: Update FORM selector to take in specific form instead of calling for all forms
export const selectSingleForm = (formName: FormName) =>
  createSelector(selectFormsState, (formState: Forms) => {
    const form = formState[formName] as FormApi
    return !form || (form && !form.getState) ? null : form.getState().values
  })

export const selectMapViewport = (state: ReduxState) => state.mapSlice.viewport
export const selectMapType = (state: ReduxState) => state.mapSlice.mapType

export const selectMapShouldFitBounds = (state: ReduxState) => state.mapSlice.viewport.shouldFitBounds

export const selectMapCenter = (state: ReduxState) => state.mapSlice.viewport.center
export const selectMapZoom = (state: ReduxState) => state.mapSlice.viewport.zoom

export const selectTicket = (state: ReduxState) => state.ticketSlice.ticket as TransformedTicket
export const selectTicketError = (state: ReduxState) => state.ticketSlice.ticketError
export const selectLocation = (state: ReduxState) => (name: keyof LocationState) => state.locationSlice[name]
export const selectTempMarker = (state: ReduxState) => state.tempMarkerSlice.tempMarker
export const selectSinglePinView = (state: ReduxState) => state.tempMarkerSlice.singlePinView
export const selectSmartAssignment = (state: ReduxState) => state.smartAssignmentSlice
export const selectLastVinSearched = (state: ReduxState) => state.connectedSearchSlice.lastVinSearched

export const selectFleetVehicle = (state: ReduxState) => state.fleetVehicleSlice.vehicle
export const selectFleetVehicleHistory = (state: ReduxState) => state.fleetVehicleSlice.vehicleHistory
export const selectAllEvents = (state: ReduxState) => state.currentEventSlice
export const selectCurrentEvent = (state: ReduxState) => (eventType?: EventTypes) => {
  if (eventType && state.currentEventSlice[eventType]) {
    return state.currentEventSlice[eventType]
  }

  const filteredEvents = Object.entries(state.currentEventSlice).filter(([key, value]) => Boolean(value))[0]
  if (eventType && filteredEvents && filteredEvents[0] === eventType) {
    // Event object value from key
    return filteredEvents && filteredEvents[1]
  }
  return null
}
export const selectCurrentSoftServiceEvent = (state: ReduxState) =>
  state.currentEventSlice[EventTypes.FUEL] ??
  state.currentEventSlice[EventTypes.TIRES] ??
  state.currentEventSlice[EventTypes.TIRES_CHANGE_SPARE] ??
  state.currentEventSlice[EventTypes.WINCH] ??
  state.currentEventSlice[EventTypes.LOCKOUT] ??
  state.currentEventSlice[EventTypes.JUMPSTART]

export const selectTicketHistoryEvents = (state: ReduxState) => state.callHistorySlice.events
export const selectRecentDispatches = (state: ReduxState) => state.recentDispatchSlice
