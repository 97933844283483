import * as React from 'react'
import { PartialULYDispatch } from 'types/global'
import { useTranslation } from 'react-i18next'
import { MdChevronRight, MdExpandMore } from 'react-icons/md'
import { isArray } from 'lodash-es'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Flex, Label } from 'components/primitives'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { CallTypes } from 'types/callstore'
import Divider from 'components/Divider'
import { callEvents } from 'pages/Ticket/routes/Default/Details/CallHistory/CallEvent'
import { monza } from 'theme/colors'
import { useULYDispatchQuery } from 'hooks/urgent.ly/useULYDispatch'
import { formatDispatchTimestamp, UnionCallTypes } from './DispatchAAAEvent'
import { DynamoEvents } from 'types/events'
import { UlyServices, UlyStatusCodes } from 'types/urgently'
import { useDispatch } from 'react-redux'
import { removeRecentULYDispatch } from 'redux/recentDispatch/recentDispatchSlice'

const GridWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 50px auto 20px;
    grid-gap: 8px;
  }
`

const RetryLink = styled(Label as any)`
  cursor: pointer;
  color: ${palette('calypso')};
`

export const DispatchUlyEventProxy: React.FC<{ event: DynamoEvents }> = ({ event }) => {
  const { eventType, attributes } = event

  const serviceCases = (
    isArray(attributes.serviceCase) ? attributes.serviceCase : [attributes.serviceCase]
  ) as PartialULYDispatch[]

  return (
    <>
      {serviceCases.map((serviceCase, i) => (
        <DispatchUlyEventProxyDetails
          key={i}
          dispatch={serviceCase}
          event={event}
          eventType={eventType}
          timestamp={formatDispatchTimestamp(event.audit?.[0]?.createTimestamp)}
        />
      ))}
    </>
  )
}

function renderServiceStatus(service?: UlyServices | null, index?: number) {
  if (service === undefined || service === null) return null

  const isCancelled =
    service.statusCode === UlyStatusCodes.CANCELLED_BY_CUSTOMER ||
    service.statusCode === UlyStatusCodes.CANCELLED_BY_CUSTOMER_CHARGEABLE ||
    service.statusCode === UlyStatusCodes.CANCELLED_BY_PROVIDER ||
    service.statusCode === UlyStatusCodes.CANCELLED_BY_DRIVER_FOR_CUSTOMER ||
    service.statusCode === UlyStatusCodes.CANCELLED_NOT_IN_SERVICE_AREA ||
    service.statusCode === UlyStatusCodes.CANCELLED_NO_PROVIDERS_FOUND ||
    service.statusCode === UlyStatusCodes.CLOSED_CANCELLED ||
    service.statusCode === UlyStatusCodes.CLOSED_CANCELLED_CHARGEABLE

  const isCompleted = service.statusCode === UlyStatusCodes.COMPLETE_BY_PROVIDER

  const status = isCancelled
    ? 'Cancelled'
    : isCompleted
      ? 'Completed'
      : UlyStatusCodes?.[service.statusCode as UlyStatusCodes]?.replace(/[_]+/g, ' ').toLowerCase() || 'N/A'

  return (
    <div
      key={index}
      style={{
        marginLeft: index ? 8 : 0,
        marginTop: 0,
        fontSize: 12,
        textTransform: 'capitalize',
      }}
    >{`${service?.name}: ${status}`}</div>
  )
}

type DetailProps = {
  dispatch: PartialULYDispatch
  eventType?: string | null
  timestamp?: string
  event?: DynamoEvents
}

const DispatchUlyEventProxyDetails: React.FC<DetailProps> = ({ dispatch, event, eventType, timestamp }) => {
  const { t } = useTranslation()
  const reduxDispatch = useDispatch()
  const removeRecentDispatch = (caseId: string) => reduxDispatch(removeRecentULYDispatch(caseId))
  const [toggleOpen, setToggleOpen] = React.useState<boolean>(false)
  const callEventType = UnionCallTypes[eventType?.toUpperCase() as keyof typeof CallTypes]
  const { data, loading, error, refetch } = useULYDispatchQuery(dispatch.caseId)
  const Component = callEvents[eventType as keyof typeof callEvents]
  let isUnattended = false

  data?.services.forEach((service) => {
    service?.tags?.forEach((tagItem) => {
      if (tagItem?.tag === 'unattended.tow') {
        if (tagItem.value === 'true') {
          isUnattended = true
        }
      }
    })
  })

  const unattendedText = isUnattended ? ' - Unattended' : ''

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setToggleOpen(!toggleOpen)
          if (!toggleOpen) {
            removeRecentDispatch(dispatch.caseId)
          }
        }}
      >
        <GridWrapper>
          <div className="grid">
            <div>{eventType && <CallTypeIcon callType={callEventType} gutter="sm" />}</div>
            <Flex style={{ flexDirection: 'column' }}>
              <Flex>
                {!loading && (
                  <Label style={{ fontWeight: 'bold', cursor: 'pointer', marginRight: 20 }}>
                    {`Case # ${data?.case.ulyCaseId} - ${callEventType}` + unattendedText}
                  </Label>
                )}
              </Flex>
              {loading && <Label style={{ fontStyle: 'italic' }}>{t('Loading')}</Label>}

              <Flex>{!loading && !error && data && data.services.map(renderServiceStatus)}</Flex>
              <Flex>{!loading && !error && <Label style={{ whiteSpace: 'nowrap' }}> {timestamp} </Label>}</Flex>
              {!loading && error && (
                <>
                  <Label style={{ color: monza }}>{t('Unable to retrieve status from ULY')}</Label>
                  <RetryLink
                    onClick={async (event) => {
                      const shouldFireQuery = true
                      event.preventDefault()
                      event.stopPropagation()
                      await refetch(shouldFireQuery)
                    }}
                  >
                    {t('Retry')}
                  </RetryLink>
                </>
              )}
            </Flex>
            <div>{toggleOpen ? <MdExpandMore /> : <MdChevronRight />}</div>
          </div>
        </GridWrapper>
      </div>
      {toggleOpen && (
        <>
          <Divider />
          {Component && <Component {...(event as DynamoEvents)} />}
        </>
      )}
    </>
  )
}

export default DispatchUlyEventProxy
