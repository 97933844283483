import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CustomVinState, QuickNotesAction, QuickSearchActions, SidebarAction } from 'types/global'
import { getInitialAppState, handleArrayCheck } from 'redux/helpers/appHelpers'
import { AppState } from 'types/redux'
import { FormStore, Forms, RegisterFormAction, UnregisterFormAction } from 'types/form'
import { mergeWith } from 'lodash'

const initialState: AppState = getInitialAppState()

const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    // Form
    registerForm: (state: AppState, action: PayloadAction<RegisterFormAction>) => {
      const formName = action.payload.name
      const form = action.payload.form

      state.forms = { ...state.forms, [formName]: mergeWith({}, { ...state.forms[formName] }, { ...form }) }
      return state
    },
    unregisterForm: (state: AppState, action: PayloadAction<UnregisterFormAction>) => {
      const formName = action.payload.name
      const { [formName]: unwanted, ...otherForms } = state.forms

      state.forms = otherForms as Forms
      return state
    },
    updateFormState: (state: AppState, action: PayloadAction<Partial<FormStore>>) => {
      state.forms = mergeWith({}, { ...state.forms }, { ...action.payload }, handleArrayCheck)
      return state
    },
    resetFormState: (state: AppState) => {
      state.forms = initialState.forms
      return state
    },

    // Custom Vin
    setCustomVinInput: (state: AppState, action: PayloadAction<CustomVinState>) => {
      state.customVin.vinInput = action.payload.vinInput
      return state
    },
    resetCustomVinInputState: (state: AppState) => {
      state.customVin = initialState.customVin
      return state
    },

    // Sidebar
    toggleSidebar: (state: AppState, action: PayloadAction<SidebarAction>) => {
      const side = action.payload.side
      const toggleOpen = action.payload.toggle === 'OPEN'
      const toggleClose = action.payload.toggle === 'CLOSE'

      // If the side is closed and we call open sidebar
      if (state.sidebar[side] === false && toggleOpen) {
        state.sidebar[side] = true
      }
      // If the side is open and we call close sidebar
      if (state.sidebar[side] === true && toggleClose) {
        state.sidebar[side] = false
      }
    },
    resetSidebarState: (state: AppState) => {
      state.sidebar = initialState.sidebar
      return state
    },

    // Tabs
    updateTabsState: (state: AppState, action: PayloadAction<{ tabs: AppState['tabs'] }>) => {
      const tabsState = state.tabs
      const tabsPayload = action.payload.tabs

      if (tabsPayload.length === tabsState.length) state.tabs = action.payload.tabs

      return state
    },
    resetTabState: (state: AppState) => {
      state.tabs = initialState.tabs
      return state
    },

    // QuickNotes
    updateQuickNotes: (state: AppState, action: PayloadAction<QuickNotesAction>) => {
      state.quickNotes = { ...action.payload }
      return state
    },
    resetQuickNotesState: (state: AppState) => {
      state.quickNotes = initialState.quickNotes
      return state
    },

    // QuickSearch
    setQuickSearchView: (state: AppState, action: PayloadAction<QuickSearchActions>) => {
      state.quickSearch.currentView = action.payload.view
      return state
    },

    resetAppState: (state: AppState) => {
      state = initialState
      return state
    },
  },
})

export const {
  registerForm,
  unregisterForm,
  updateFormState,
  resetFormState,
  toggleSidebar,
  resetSidebarState,
  updateTabsState,
  resetTabState,
  updateQuickNotes,
  resetQuickNotesState,
  setQuickSearchView,
  resetAppState,
  setCustomVinInput,
  resetCustomVinInputState,
} = appSlice.actions

export default appSlice.reducer
