import { DateTime } from 'luxon'
import { useLocalTimeZone } from 'hooks/utils'
import useLocations from 'hooks/redux/useLocations'

export const useDispatchEta = (service: any, flag: boolean = false) => {
  const locations = useLocations()

  let lat, lon, eta
  if ((locations.vehicleLocation || locations.callerLocation) && flag) {
    const latitude = locations.vehicleLocation?.latitude || locations.callerLocation?.latitude
    const longitude = locations.vehicleLocation?.longitude || locations.callerLocation?.longitude
    lat = latitude
    lon = longitude
    eta = service?.status?.eta || service?.eta
  }

  const { timezone: zone } = useLocalTimeZone({ lat, lon })

  const dispatchEta = eta && zone && DateTime.fromISO(eta, { zone })

  return {
    dispatchEta: dispatchEta ? `${dispatchEta.toFormat('t')} ${dispatchEta.offsetNameLong.split(' ')[0]}` : null,
    zone,
  }
}

export default useDispatchEta
