import * as React from 'react'
import { Marker as TMarker, MarkerType } from 'types/googleMaps'
import { useGoogleMaps } from 'hooks/map'
import { useFeatureGroup } from '../GoogleFeatureGroup'
import { isNil } from 'lodash-es'

export type MarkerProps = {
  latitude: number
  longitude: number
  icon: any
  onClick?: any
  children?: React.ReactNode
} & Pick<TMarker, 'type'>

export const MarkerContext = React.createContext<google.maps.Marker | null>(null)

enum zIndexByMarkerType {
  CALLER = 5,
  VEHICLE = 6,
  BODYSHOP = 5,
}

function getZIndexByType(type: MarkerType | undefined) {
  if (type === undefined) return 1

  let specifiedIndex = Object.keys(zIndexByMarkerType).indexOf(type)

  if (specifiedIndex === -1) return 1

  let newZIndex = Number(zIndexByMarkerType[type])

  return newZIndex
}

function createNewMarker(icon: any, type: MarkerProps['type'], loc: google.maps.LatLng) {
  let marker = new google.maps.Marker({
    position: loc,
    icon: {
      url: `http://maps.google.com/mapfiles/kml/paddle/red-blank.png`,
      scaledSize: new google.maps.Size(45, 50),
    },
    zIndex: getZIndexByType(type),
  })
  if (icon.codePoint) {
    marker = new google.maps.Marker({
      position: loc,
      label: {
        text: icon.codePoint, // codepoint from https://fonts.google.com/icons
        fontFamily: 'Material Icons',
        color: icon.color,
        fontSize: '24px',
      },
      icon: {
        url: `http://maps.google.com/mapfiles/kml/paddle/${icon.bgColor}.png`,
        scaledSize: new google.maps.Size(55, 65),
        labelOrigin: new google.maps.Point(27.5, 16.5),
      },
      zIndex: getZIndexByType(type),
    })
  }
  if (icon.svgPath) {
    marker = new google.maps.Marker({
      position: loc,
      icon: {
        path: icon.svgPath,
        fillColor: icon.color,
        fillOpacity: 1,
        scale: 0.35,
        strokeColor: '#000000', // TODO: Change up stroke color / weight
        strokeWeight: 1,
      },
      zIndex: getZIndexByType(type),
    })
  }

  // Adding in EventListeners
  const iconProps = marker.getIcon() as google.maps.Symbol
  const labelProps = marker.getLabel() as google.maps.MarkerLabel
  const zIndex = getZIndexByType(type)

  marker.addListener('mouseover', () => {
    labelProps && marker.setLabel({ ...labelProps, fontSize: '32px' })
    iconProps &&
      marker.setIcon({
        ...iconProps,
        size: new google.maps.Size(70, 80),
        scaledSize: new google.maps.Size(70, 80),
        labelOrigin: new google.maps.Point(35, 20),
        scale: 0.5,
      })
    zIndex && marker.setZIndex(10)
  })

  // Reset Size settings
  marker.addListener('mouseout', () => {
    labelProps && marker.setLabel({ ...labelProps })
    iconProps && marker.setIcon({ ...iconProps })
    zIndex && marker.setZIndex(zIndex)
  })

  return marker
}

export const Marker = ({ latitude, longitude, icon, onClick, children, type }: MarkerProps) => {
  const { map } = useGoogleMaps()
  const featureGroup = useFeatureGroup()
  const [marker, setMarker] = React.useState<google.maps.Marker | null>(null)

  React.useEffect(() => {
    if (map) {
      const loc = new google.maps.LatLng(latitude, longitude)
      if (!marker) {
        const marker = createNewMarker(icon, type, loc)

        // Break when we try to re-add same marker since we're in an useEffect
        if (featureGroup && !featureGroup?.getArray().some((v: google.maps.Marker) => v.getPosition()?.equals(loc))) {
          // Add in all markers to google.maps.MVCArray
          featureGroup.push(marker)
          if (!isNil(type))
            // Set markers to specific marker type on the google.maps.MVCArray ( Which extends google.maps.MVCObject )
            featureGroup.set(type, featureGroup.getArray())
          marker.setMap(map)
        } else {
          marker.setMap(map)
        }
        setMarker(marker)
      } else {
        marker.setPosition(loc)
      }
    }
  }, [icon, marker, map, type, latitude, longitude])

  React.useEffect(() => {
    return () => {
      if (map) {
        if (marker) {
          if (featureGroup) {
            if (!isNil(type))
              // Clear out the marker in the array for the specific marker type
              featureGroup.set(type, [])

            marker.setMap(null)
          } else {
            map.set('marker', marker)
          }
        }
      }
    }
  }, [marker, featureGroup, type, map])

  React.useEffect(() => {
    if (marker && onClick) {
      const markerListener = marker.addListener('click', onClick)
      return () => {
        google.maps.event.removeListener(markerListener)
      }
    }
  }, [marker, onClick])

  return <MarkerContext.Provider value={marker}>{children}</MarkerContext.Provider>
}

export function useMarker() {
  const marker = React.useContext(MarkerContext)
  return marker
}

export function useMarkerContainerPoint(): google.maps.Point | null {
  const { map } = useGoogleMaps()
  const marker = useMarker()
  if (!marker || !map) return null

  const mapProjection = map.getProjection()
  const latlng = marker.getPosition()

  if (mapProjection && latlng) {
    const scale = Math.pow(2, map.getZoom()!)
    const nw = new google.maps.LatLng(map.getBounds()!.getNorthEast().lat(), map.getBounds()!.getSouthWest().lng())
    const worldCoordinateNW = mapProjection.fromLatLngToPoint(nw)
    const worldCoordinate = mapProjection.fromLatLngToPoint(latlng)

    if (worldCoordinate && worldCoordinateNW) {
      const pixelOffset = new google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
      )
      return pixelOffset
    }
  }
  return null
}

export default Marker
