import React from 'react'
import * as Ariakit from '@ariakit/react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { Label, Paragraph } from 'components/primitives'
import Input, { YesNoTriggers, YesNoRadio } from 'components/Input'
import { Condition } from 'components/fields'
import Location, { FormValueType } from 'components/Input/Location'
import Button from 'components/Button'
import { BackNavigation } from 'components/Step'
import { Tow } from 'types/callstore'
import { reverseGeocode } from 'components/useReverseGeocoder'
import { transformExtendedAddress } from 'utils/aaa'
import { LocationPayload } from 'types/location'
import { useServiceProxy } from 'hooks/kong'
import { useDispatch, useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'
import useLocations, { useCallerDestinationLocation, useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { updateLocation } from 'redux/location/locationSlice'

const OtherSelection = () => {
  const { t } = useTranslation()
  const form = useForm()
  const callerDestinationLoc = useCallerDestinationLocation()
  const formState = form.getState().values
  const rideAlongValue = formState.rideAlong

  // Initial load - set rideAlongLocation to null
  React.useEffect(() => {
    form.change('rideAlongLocation', null)
  }, [])

  React.useEffect(() => {
    if (callerDestinationLoc) {
      const address = `${callerDestinationLoc?.address.streetNumber} ${callerDestinationLoc?.address.street} ${callerDestinationLoc?.address.city} ${callerDestinationLoc?.address.state} ${callerDestinationLoc?.address.postalCode}`
      form.change('rideAlongLocation', `${address}`)
    }
  }, [callerDestinationLoc])

  return (
    <Margin spacing="md">
      <Location.CallerDestination
        name="rideAlongLocation"
        label={t('Customer Dropoff Location')}
        valueType={rideAlongValue === false ? undefined : FormValueType.Address}
        required
      />
    </Margin>
  )
}
const NetworkSelection = () => {
  const { participant } = useSelector(selectSmartAssignment)
  const { name, address } = participant!.entity
  const { addressLine1, city, administrativeArea, postalCode } = address!
  const form = useForm()

  React.useEffect(() => {
    if (participant) {
      form.change('rideAlongLocation', `${name}, ${addressLine1}, ${city}, ${administrativeArea} ${postalCode}`)
    }
  }, [])

  return (
    <Margin spacing="md">
      <Paragraph>{name}</Paragraph>
      <Paragraph>{addressLine1}</Paragraph>
      <Paragraph spacing="md">{`${city}, ${administrativeArea} ${postalCode}`}</Paragraph>
    </Margin>
  )
}
const ExchangeSelection = () => {
  const form = useForm()
  const { t } = useTranslation()
  const formState = form.getState().values
  const exchangeLocation =
    formState.exchangeNotes && formState.serviceLocations.exchangeLocation ? formState.serviceLocations.exchangeLocation : null

  React.useEffect(() => {
    if (exchangeLocation) {
      const loc = `${exchangeLocation.brand}, ${exchangeLocation.address.street_addresses[0]} ${exchangeLocation.address.city} ${exchangeLocation.address.country_subdivision_code} ${exchangeLocation.address.postal}`
      form.change('rideAlongLocation', loc)
    }
  }, [])
  return (
    <Margin spacing="md">
      <Label>{t('Exchange Branch Location')}</Label>
      <Paragraph>
        <Paragraph spacing="sm">{`${exchangeLocation.brand} - ${exchangeLocation.additional_data.group_branch_number}`}</Paragraph>
        {exchangeLocation.name}
      </Paragraph>
      <Paragraph>{`${exchangeLocation.address.street_addresses[0]}`}</Paragraph>
      <Paragraph spacing="sm">{`${exchangeLocation.address.city} ${exchangeLocation.address.country_subdivision_code}, ${exchangeLocation.address.postal}`}</Paragraph>
    </Margin>
  )
}

export const CustomerDropoffDestination = () => {
  const { t } = useTranslation()
  const proxy = useServiceProxy()
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()
  const formState = form.getState().values
  const rideAlongValue = formState.rideAlong
  const customerSuppliedValue = formState.customerSupplied
  const locations = useLocations()
  const dispatch = useDispatch()
  const customerWithVehicle = formState.customerWithVehicle
  const rideAlongLocation = formState.rideAlongLocation
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const disabled = !customerWithVehicle || (customerWithVehicle === 'no' && !callerLocation?.latitude) || !rideAlongLocation
  const disabledCustomerLocation = customerWithVehicle === 'no' ? !callerLocation?.latitude : !vehicleLocation?.latitude
  const isExchangeFlow = formState.exchange || false

  React.useEffect(() => {
    if (isExchangeFlow) {
      form.change('customerLocation', 'exchangeBranchDropoff')
    } else {
      form.change('customerLocation', 'vehicleDestDropoff')
    }
  }, [])

  return (
    <>
      <>
        <BackNavigation
          onClick={() => {
            form.change('unattended', false)
            form.change('rideAlong', undefined)
            form.change('branch', null)
            controlsContext?.move(controlsContext?.previous())
          }}
        />
        <Margin spacing="sm">
          <YesNoRadio name="customerWithVehicle" trigger={YesNoTriggers.NO} label={t('tow.exchange.withDisabledVehicle')}>
            <Margin spacing="md">
              <Location.Caller name="callerLocation" required />
            </Margin>
          </YesNoRadio>
        </Margin>
        {customerSuppliedValue && customerWithVehicle && (
          <Button.Primary
            disabled={disabledCustomerLocation}
            style={{ width: '100%' }}
            onClick={() => {
              if (customerWithVehicle === 'yes') {
                form.change('serviceLocations.callerLocation', {
                  ...locations.vehicleLocation!,
                  serviceLocationType: 'CALLER',
                })
                dispatch(
                  updateLocation({
                    name: 'callerLocation',
                    location: { ...locations.vehicleLocation!, serviceLocationType: 'CALLER' },
                  }),
                )
              }
              controlsContext?.move(controlsContext?.next())
            }}
          >
            {t('Set Customer Location')}
          </Button.Primary>
        )}

        {!customerSuppliedValue && (
          <>
            <Margin spacing="sm">
              <Label as="h3" style={{ fontSize: 16 }}>
                {t('Customer Dropoff Location')}
              </Label>
              {isExchangeFlow && (
                <Margin spacing="sm">
                  <Input.Radio name="customerLocation" value="exchangeBranchDropoff">
                    {t('Same as Exchange Branch Location')}
                  </Input.Radio>
                </Margin>
              )}
              <Margin spacing="sm">
                <Input.Radio name="customerLocation" value="vehicleDestDropoff">
                  {t('Same as Vehicle Dropoff Destination')}
                </Input.Radio>
              </Margin>
              <Margin spacing="sm">
                <Input.Radio name="customerLocation" value="otherDropoff">
                  {t('Other')}
                </Input.Radio>
              </Margin>

              <Condition when="customerLocation" is="exchangeBranchDropoff">
                <ExchangeSelection />
              </Condition>
              <Condition when="customerLocation" is="vehicleDestDropoff">
                <NetworkSelection />
              </Condition>
              <Condition when="customerLocation" is="otherDropoff">
                <OtherSelection />
              </Condition>
            </Margin>
            <Button.Primary
              style={{ width: '100%' }}
              disabled={disabled}
              onClick={async () => {
                const { rideAlongLocation } = form.getState().values as Tow
                if (typeof rideAlongLocation === 'object' && rideAlongValue === false) {
                  const customLocation = rideAlongLocation as LocationPayload
                  const lat = customLocation.latitude
                  const lon = customLocation.longitude
                  const geocodeAddress = await reverseGeocode(proxy, lat, lon)
                  form.change('thirdPartyLocation', {
                    ...customLocation,
                    ...(geocodeAddress &&
                      geocodeAddress !== 'ZERO_RESULTS' && {
                        extendedPayload: {
                          address: transformExtendedAddress(geocodeAddress),
                        },
                      }),
                  })
                }

                if (rideAlongLocation) {
                  form.change('rideAlongLocationSet', true)
                  controlsContext?.move(controlsContext?.next())
                }

                if (customerWithVehicle === 'no') {
                  form.change('serviceLocations.callerLocation', {
                    ...locations.callerLocation!,
                    serviceLocationType: 'CALLER',
                  })
                  dispatch(
                    updateLocation({
                      name: 'callerLocation',
                      location: { ...locations.callerLocation!, serviceLocationType: 'CALLER' },
                    }),
                  )
                }
                if (customerWithVehicle === 'yes') {
                  form.change('serviceLocations.callerLocation', {
                    ...locations.vehicleLocation!,
                    serviceLocationType: 'CALLER',
                  })
                  dispatch(
                    updateLocation({
                      name: 'callerLocation',
                      location: { ...locations.vehicleLocation!, serviceLocationType: 'CALLER' },
                    }),
                  )
                }
              }}
            >
              {t('Set Dropoff Location')}
            </Button.Primary>
          </>
        )}
      </>
    </>
  )
}

export default CustomerDropoffDestination
