import { isArray } from 'lodash'
import CallTypes, { LdrClaim, callerDefault, officerDefault } from 'types/callstore'
import { Forms } from 'types/form'
import { Tab, ViewTypes } from 'types/global'

export function getInitialAppState() {
  return {
    forms: getFormState(),
    customVin: getCustomVinState(),
    sidebar: getSidebarState(),
    tabs: getTabsState(),
    quickNotes: getQuickNotesState(),
    quickSearch: getQuickSearchState(),
  }
}

function getCustomVinState() {
  return { vinInput: null }
}

function getFormState() {
  return {
    caller: callerDefault,
    notes: null,
    isConnectedCarPin: false,
    isConnectedCarConsent: false,
    callType: null,
    ldr: {} as LdrClaim,
    workflow: [],
    officer: officerDefault,
  } as unknown as Forms
}

function getSidebarState() {
  return {
    left: true,
    right: false,
  }
}

function getTabsState() {
  const tabs: Array<Tab> = []
  Object.values(CallTypes).map((i) => tabs.push({ name: i, enabled: false }))
  return tabs
}

function getQuickSearchState() {
  return { currentView: ViewTypes.main }
}

function getQuickNotesState() {
  return {
    callerName: null,
    number: null,
    countryCode: {
      label: 'United States +1',
      value: 'us',
    },
    notes: null,
  }
}

export const handleArrayCheck = (a: any, b: any) => (isArray(b) ? b : undefined)
