import { Link } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdAccessTime, MdStore, MdPlace, MdToday } from 'react-icons/md'
import { Heading } from 'components/primitives'
import Divider from 'components/Divider'
import { MarkerType } from 'types/googleMaps'
import { ListItem } from 'components/List'
import LocalTime from 'components/LocalTime'
import { MarkerTypeLabels } from '../utils'

type Props = {
  markerType: MarkerType
  title: String
  address?: String
  formattedCityStateZip?: String
  pickupTime?: String
  returnTime?: String
  placeId: String
  localTimeZone?: string | null
}

export const HotelTooltip: React.FC<Props> = ({
  markerType,
  title,
  address,
  formattedCityStateZip,
  pickupTime,
  returnTime,
  placeId,
  localTimeZone,
}) => {
  const { t } = useTranslation()
  const time = (pickupTime && new Date(pickupTime as string)) || (returnTime && new Date(returnTime as string))

  return (
    <div>
      <Heading as="h5">{t(MarkerTypeLabels[markerType] as string)}</Heading>
      <ListItem>
        <ListItem.Icon icon={MdStore} color="elm" />
        <ListItem.Text>
          <div>{title}</div>
        </ListItem.Text>
      </ListItem>
      <ListItem>
        <ListItem.Icon icon={MdPlace} color="elm" />
        <ListItem.Text>
          {address}
          <br />
          {formattedCityStateZip}
        </ListItem.Text>
      </ListItem>
      {time && (
        <ListItem>
          <ListItem.Icon icon={MdToday} color="elm" />
          <ListItem.Text>
            {t('date.standard', {
              date: time,
            })}
          </ListItem.Text>
        </ListItem>
      )}
      {localTimeZone && (
        <ListItem>
          <ListItem.Icon icon={MdAccessTime} color="elm" />
          <LocalTime timeZone={localTimeZone} />
        </ListItem>
      )}
      {markerType === MarkerType.RENTALRETURN && (
        <>
          <Divider />
          <Heading as="h5">{t('Return')}</Heading>
          <ListItem>
            <ListItem.Icon icon={MdToday} color="elm" />
            <ListItem.Text>
              {returnTime &&
                t('date.standard', {
                  date: new Date(returnTime as string),
                })}
            </ListItem.Text>
          </ListItem>
        </>
      )}
      <ListItem>
        <Link to={`place/HOTELS/${placeId}`}>{t('View Details')}</Link>
      </ListItem>
    </div>
  )
}

export default HotelTooltip
