import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomVinInput, selectTicket } from 'redux/appStore'
import MdSpinner from 'react-md-spinner'
import Input from 'components/Input'
import { Form } from 'components/FormManager'
import colors, { calypso, cardinal } from 'theme/colors'
import { MdClose, MdCheck, MdDirectionsCar } from 'react-icons/md'
import { Flex } from 'components/primitives'
import { ListItem } from 'components/List'
import { useFieldValue } from 'hooks/utils'
import { setCustomVinInput } from 'redux/slices/appSlice'
import { useForm } from 'react-final-form'
import { useFleetVinSearch } from 'hooks/kong/useFleetVinSearch'
import Margin from 'components/Margin'

declare module 'types/form' {
  export interface Forms {
    customVin: FormApi
  }
}

const VinInputField = () => {
  const ticket = useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)
  const dispatch = useDispatch()
  const form = useForm()
  const inputValue = useFieldValue('vinInput')

  const { loading, error } = useFleetVinSearch()

  const handleOnClick = () => {
    const vinInput = inputValue !== '' ? inputValue : (customVinInput ?? ticket.vehicle?.vin)
    dispatch(setCustomVinInput({ vinInput }))
  }

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Flex style={{ width: '100%' }}>
        <Flex style={{ marginTop: 8 }}>
          <ListItem.Icon icon={MdDirectionsCar} />
        </Flex>
        <Input.Textfield
          name="vinInput"
          placeholder="Enter in the vehicle VIN"
          maxLength={17}
          defaultValue={customVinInput ?? undefined}
        />

        {loading ? (
          <Flex style={{ margin: 8 }}>
            <MdSpinner singleColor={calypso} size={24} />
          </Flex>
        ) : (
          <>
            <MdClose
              style={{ cursor: 'pointer', marginLeft: 4, marginTop: 8 }}
              color={colors.cardinal}
              onClick={() => form.change('vinInput', '')}
            />
            <MdCheck
              style={{ cursor: 'pointer', marginLeft: 4, marginTop: 8 }}
              color={colors.mountainMeadow}
              onClick={handleOnClick}
            />
          </>
        )}
      </Flex>
      {error ? (
        <Margin gutter="sm" style={{ color: cardinal }}>
          {error || error.message}
        </Margin>
      ) : null}
    </Flex>
  )
}

export const VehicleVinInputForm = () => {
  return (
    <Form name="customVin" schema={schema}>
      <Flex>
        <VinInputField />
      </Flex>
    </Form>
  )
}

const schema = Yup.object().shape({
  vinInput: Yup.string().required('Please enter in a vehicle vin'),
})
