import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdChevronLeft } from 'react-icons/md'
import MdSpinner from 'react-md-spinner'
import { Flex, Heading } from 'components/primitives'
import Button from 'components/Button'
import Column from 'components/Column'
import { Dialog } from 'components/Dialog'
import * as Ariakit from '@ariakit/react'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { resetQuickNotesState } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'
import usePendoCallId from 'hooks/pendo/usePendoCallId'

const BackToSearchButton: React.FC = () => {
  const [exiting, setExiting] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const exitDialog = Ariakit.useDialogStore()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { changeHeroPendoCallId } = usePendoCallId()

  React.useEffect(() => {
    // Fire this when component unmounts instead of button onClick
    return () => {
      changeHeroPendoCallId()
      exitDialog.hide()
      dispatch(resetQuickNotesState())
      localStorage.removeItem('localFormStore') // Remove Caller specific form items
      setExiting(false)
    }
  }, [])

  return (
    <>
      <Ariakit.DialogProvider store={exitDialog}>
        <Dialog {...exitDialog} aria-label="Back To Search">
          {exiting ? (
            <Column>
              <Heading as="h4">{t('Discarding changes...')}</Heading>
              <MdSpinner singleColor={calypso} size={64} style={{ margin: `${pxToRem(32)} 0` }} />
            </Column>
          ) : (
            <Column>
              <Heading as="h4">{t('Unsaved Changes')}</Heading>
              <div
                style={{
                  textAlign: 'center',
                  margin: `${pxToRem(16)} 0 ${pxToRem(24)}`,
                  width: pxToRem(312),
                }}
              >
                {t(
                  'A call type or service has not been selected.  Are you sure you want to exit without saving call information?',
                )}
              </div>
              <Flex>
                <Button.Primary style={{ marginRight: pxToRem(24) }} onClick={exitDialog.hide}>
                  {t('Return to call')}
                </Button.Primary>
                <Button.Regular
                  onClick={async () => {
                    setExiting(true)
                    setTimeout(() => {
                      navigate('/ticket')
                    }, 1500)
                  }}
                >
                  {t('Discard and exit')}
                </Button.Regular>
              </Flex>
            </Column>
          )}
        </Dialog>

        <Flex style={{ cursor: 'pointer', alignItems: 'center' }} onClick={exitDialog.show}>
          <MdChevronLeft />
          {t('Search')}
        </Flex>
      </Ariakit.DialogProvider>
    </>
  )
}

export default BackToSearchButton
