import { AxiosResponse } from 'axios'
import { AaaService } from './aaa'
import { CarClassCategories } from './carClass'
import { UlyCustomer, UlyContact } from './urgently'
export { Brands } from './branch'
export type { Branch, BranchInfoFragment } from './branch'
export {
  AvailableStatusCodes,
  CarClassCategories,
  Core,
  Premium_Sedan,
  Sport,
  Hybrid_Electric,
  Suv,
  Large_Suv,
  Vans,
  Passenger_Vans,
  Trucks,
} from './carClass'

export type { CarClassAvailability, CarClassStatus, CarClasses } from './carClass'

export const CarClassLabels: { [key: string]: string } = {
  [CarClassCategories.CORE]: 'Core',
  [CarClassCategories.PREMIUM_SEDAN]: 'Premium Sedan',
  [CarClassCategories.SPORT]: 'Sport',
  [CarClassCategories.HYBRID_ELECTRIC]: 'Hybrid - Electric',
  [CarClassCategories.SUV]: 'SUV',
  [CarClassCategories.LARGE_SUV]: 'Large SUV',
  [CarClassCategories.VANS]: 'Vans',
  [CarClassCategories.PASSENGER_VANS]: 'Passenger Vans',
  [CarClassCategories.TRUCKS]: 'Trucks',
}

export enum AssignmentNetworks {
  SERVICE = 'SERVICE',
  TIRE = 'TIRE',
  BODY = 'BODY',
}

export type Maybe<T> = T | null

export type Spaces = 'none' | 'sm' | 'md' | 'lg'

export type SelectOption<E extends Enum<E> | string = string | any> = {
  label: string
  value: E
}

type Enum<E> = Record<keyof E, number | string> & Record<number, string>

export type Caller = {
  callerName: Maybe<string>
  phone?: CallerPhone
}

export type CallerPhone = {
  number: Maybe<string>
  countryOption: Maybe<SelectOption<any>>
  countryCode: SelectOption<any>
}

export type EquipmentInfo = {
  shortDescription: string
  localizedFullDescription: string
  value?: string
}

export type LegalId = {
  countryCode?: Maybe<string>
  countrySubdivision?: Maybe<string>
  expirationDate?: Maybe<string>
  issueDate?: Maybe<string>
  number?: Maybe<string>
  type?: Maybe<string>
} & TypeCountryOptions

export enum ServiceLocationType {
  RENTAL_PICKUP = 'RENTAL_PICKUP',
  BREAKDOWN = 'BREAKDOWN',
  TOW_DESTINATION = 'TOW_DESTINATION',
}

export enum TroubleCode {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7',
  T8 = 'T8',
  T9 = 'T9',
  A7 = 'A7',
  B7 = 'B7',
  T0 = 'T0',
}

export enum TroubleCodeType {
  PACESETTER = 'PACESETTER',
  PACESETTER2 = 'PACESETTER2',
  PROBLEM = 'PROBLEM',
  PROBLEM2 = 'PROBLEM2',
  RESOLUTION = 'RESOLUTION',
  CLEARING = 'CLEARING',
}

export type Participant = {
  isHerePlace?: Maybe<boolean>
  isGooglePlace?: Maybe<boolean>
  assigneeEntityId: number
  entity: ParticipantEntity
  location?: Maybe<ParticipantLocation>
  distanceStats?: Maybe<ParticipantDistance>
  properties?: Maybe<ParticipantProperties>
  businessHours?: Maybe<any>
}

export type ParticipantAddress = {
  addressLine1?: Maybe<string>
  city?: Maybe<string>
  administrativeArea?: Maybe<string>
  country?: Maybe<string>
  postalCode?: Maybe<string>
}

export type ParticipantContact = {
  readonly primaryPhone?: Maybe<string>
  readonly fax?: Maybe<string>
  readonly emails?: Maybe<ReadonlyArray<string>>
}

export type ParticipantEntity = {
  id: number
  name?: Maybe<string>
  entityType?: Maybe<string>
  typeId?: Maybe<number>
  address?: Maybe<ParticipantAddress>
  contactInfo?: Maybe<ParticipantContact>
}

export type ParticipantDistance = {
  distance: number
  units: string
}

export type ParticipantLocation = {
  latitude: number
  longitude: number
}

export type ParticipantMetadata = {
  recordCount?: Maybe<number>
  sortingFields?: Maybe<ReadonlyArray<Maybe<string>>>
  limitedResults?: Maybe<boolean>
}

export type ParticipantProperties = {
  readonly participantProfileStatus?: Maybe<string>
}

export type Vehicle = {
  make: string
  model: string
  year: number
  color: string
  licensePlate: string
  licensePlateState: string
  vin: string
  vinLast8: string
  sippCode: string
  lastRecordedOdometer: string
  nextPmOdometer: string
  equipmentInfo: EquipmentInfo[]
  unitNumber: string
  installedDateTime: any
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO',
}

export type YesNoCancel = YesNo | 'CANCEL'

export type ServiceProxyCall = <T>(
  method: any,
  endpoint: string,
  headers?: any,
  body?: any,
  params?: any,
) => ReturnType<(method: any, endpoint: string, headers?: any, body?: any, params?: any) => Promise<AxiosResponse<T>>>

export type None<T> = { [K in keyof T]?: never }
export type EitherOrBoth<T1, T2> = (T1 & None<T2>) | (T2 & None<T1>) | (T1 & T2)

export type Country = {
  name: string
  iso2: string
  dial: string
  format: string
}

export type StyleProps = { style?: React.CSSProperties; className?: string }

export type PartialVehicle = Pick<Vehicle, 'year' | 'make' | 'model' | 'licensePlate' | 'licensePlateState' | 'vin' | 'color'>

export type PartialVehicleWithStringYear = Pick<
  Vehicle,
  'make' | 'model' | 'licensePlate' | 'licensePlateState' | 'vin' | 'color'
> & {
  year: string
}

export type ULYCustomerContact = Pick<UlyCustomer, 'name' | 'phone'> &
  Pick<UlyContact, 'textOptIn' | 'isMobilePhone' | 'countryPhoneCode' | 'callbackName' | 'callbackNumber'>

export type PartialDispatch = {
  externalId?: string
  callKey?: string | null
  service: Partial<AaaService>
}

export type PartialULYDispatch = {
  caseId: string
}

export type TypeCountryOptions = {
  countryOption?: SelectOption<any>
  typeOption?: SelectOption<any>
}

export type WorkflowActions = {
  tires?: boolean
  tiresChangeSpare?: boolean
  jumpstart?: boolean
  lockout?: boolean
  fuel?: boolean
  winch?: boolean
  exchange?: boolean
  recovery?: boolean
  ldr?: boolean
  tow?: boolean
}

export interface QuickNotesAction {
  notes: Maybe<string>
  callerName: Maybe<string>
  number: Maybe<string>
  countryCode: SelectOption<any>
}

export interface QuickNotesState {
  notes: Maybe<string>
  callerName: Maybe<string>
  number: Maybe<string>
  countryCode: SelectOption<any>
}

export type QuickNotes = {
  notes: Maybe<string>
} & {
  callerName: Maybe<string>
  number: Maybe<string>
  countryCode: SelectOption<any>
}

export interface QuickSearchState {
  currentView: keyof typeof ViewTypes
}

export type QuickSearchActions = {
  view: keyof typeof ViewTypes
}

export enum ViewTypes {
  'main' = 'main',
  'branches' = 'branches',
  'hotels' = 'hotels',
}

export type TabsState = Array<Tab>

export type Tab = {
  name: string
  enabled: boolean
}

export enum SidebarPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface Sidebar {
  left: boolean
  right: boolean
}

export interface SidebarAction {
  side: SidebarPosition.LEFT | SidebarPosition.RIGHT
  toggle: 'OPEN' | 'CLOSE'
}

export type DeepOmit<T, K extends string> = T extends object
  ? {
      [P in keyof T as P extends K ? never : P]: T[P] extends object ? DeepOmit<T[P], K> : T[P]
    }
  : T

export interface CustomVinState {
  vinInput?: Maybe<string>
}
