import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import { SoftService } from 'types/callstore'
// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import { Form } from 'react-final-form'
import Button from 'components/Button'
import DispatchService from 'components/Dispatch/DispatchService'
import { ErrorMessage } from 'components/fields/Error'
import { useSelector } from 'react-redux'
import Input from 'components/Input'
import { useAAADispatch } from 'hooks/aaa'
import { useUpdateEvent } from 'hooks/events'
import { isRequired } from 'utils/validators'
import { selectFormsState } from 'redux/appStore'
import { StandardServiceCall } from 'types/aaa'
import { selectCurrentSoftServiceEvent } from 'redux/appStore'
import { DynamoEvents } from 'types/events'
import { mergeWith } from 'lodash-es'
import { formatErrors } from 'components/FormManager/Submit'
import { pruneAAADispatch } from 'utils/aaa'

const focusOnError = createFocusDecorator()

type Props = {
  onUpdated: () => void
  dispatchedEvent: DynamoEvents
  children: React.ReactNode
}

const UpdateSoftServiceProviders: React.FC<Props> = (props) => {
  const stepStore = Ariakit.useCompositeStore({ defaultActiveId: '1' })
  return (
    <Ariakit.CompositeProvider store={stepStore}>
      <Ariakit.Composite store={stepStore}>
        <UpdateSoftServiceForm {...props} />
      </Ariakit.Composite>
    </Ariakit.CompositeProvider>
  )
}

const UpdateSoftServiceForm: React.FC<Props> = ({ onUpdated, children, dispatchedEvent }) => {
  const { t } = useTranslation()
  const forms = useSelector(selectFormsState)
  const currentEvent = useSelector(selectCurrentSoftServiceEvent)
  const event = currentEvent?.event || dispatchedEvent
  const updateEvent = useUpdateEvent()
  const { vehicle, dispatch, notes } = event?.attributes
  const callKey = dispatch?.[0]?.callKey
  const { handleUpdate } = useAAADispatch(callKey)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const controlsContext = Ariakit.useCompositeContext()
  const currentStepid = controlsContext?.useState('activeId')
  const controlsItems = controlsContext?.useState('items') || []
  const isLast = Number(currentStepid) === controlsItems.length
  const { make, model, licensePlate, year } = vehicle ?? {}

  return (
    <>
      <Form
        onSubmit={async (values) => {
          if (!loading) {
            setError(null)
            setLoading(true)
            try {
              await forms.caller.submit() // trigger any errors in caller form
              const callerState = mergeWith({}, forms.caller.getState(), forms.caller.getState().values)

              if (!callerState.valid) {
                let errorKeys = Object.keys(callerState.errors as any)
                if (errorKeys.includes('drivers')) {
                  if (callerState.values.selectedCaller === 'account' || callerState.values.selectedCaller === 'other') {
                    errorKeys = errorKeys.filter((element) => element !== 'drivers')
                  }
                }
                if (errorKeys.length) {
                  setError(formatErrors(errorKeys))
                  setLoading(false)
                  return
                }
              }
              const { data } = await handleUpdate(values as SoftService)
              const prunedDispatch = pruneAAADispatch(data)
              await updateEvent({
                ...event,
                serviceLocations: values.serviceLocations,
                attributes: {
                  ...event.attributes,
                  serviceLocations: values.serviceLocations,
                  notes: values.notes,
                  dispatch: [prunedDispatch],
                },
              })
              setLoading(false)
              onUpdated()
            } catch (e) {
              console.error(e)
              setError(t('error.submit') as any)
              setLoading(false)
            }
          }
        }}
        decorators={[focusOnError]}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Label>{t('labels.vehicle')}</Label>
            <Paragraph spacing="sm">{`${year} ${make} ${model} (${licensePlate})`}</Paragraph>
            {children}
            {isLast ? (
              <Input.Textarea
                name="notes"
                label={`${t('notesToProvider.label')}*`}
                defaultValue={notes as any}
                maxLength={240}
                validate={isRequired(t('required.notes'))}
              />
            ) : (
              <>
                <Label>{t('notesToProvider.label')}</Label>
                <Paragraph spacing="md">{notes}</Paragraph>
              </>
            )}
            <DispatchService dispatch={dispatch} disableCancel />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button.Primary type="submit" loading={loading} disabled={!isLast} style={{ width: '100%' }}>
              {t('confirmChanges')}
            </Button.Primary>
          </form>
        )}
      </Form>
    </>
  )
}

export function pruneUpdateDispatchData(dispatchData?: StandardServiceCall) {
  if (!dispatchData) return null
  const {
    callKey,
    service: { dispatchCenter, status },
  } = dispatchData

  return {
    callKey,
    service: { dispatchCenter, status },
  }
}

export default UpdateSoftServiceProviders
