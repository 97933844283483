import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import IncidentLocation from 'components/IncidentLocation'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'
import { DynamoEvents } from 'types/events'

function AccidentEvent(event: DynamoEvents) {
  const { serviceLocations, vehicle, recovery, exchange, ldr, tow, unsafe, garageAccessible, accessible, breakdownOption } =
    event.attributes
  const { t } = useTranslation()
  const { make, model, year, licensePlate } = vehicle ?? {}

  const vehicleLocation = serviceLocations.vehicleLocation

  const hasDispatched = recovery || exchange || ldr || tow

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('accident.heading')}
      </Heading>
      <Label>{t('labels.vehicle')}</Label>
      <Paragraph spacing="sm">{`${year} ${make} ${model} (${licensePlate})`}</Paragraph>
      <IncidentLocation.Vehicle location={vehicleLocation} />

      <br />
      <Label>{t('Breakdown Location')}</Label>
      <Paragraph spacing="sm">{breakdownOption}</Paragraph>
      {breakdownOption === BreakdownOptions.OTHER && unsafe && (
        <>
          <Label>{t('Blocking traffic or unsafe')}</Label>
          <Paragraph spacing="sm">{unsafe}</Paragraph>
        </>
      )}
      {breakdownOption === BreakdownOptions.PARKINGGARAGE && garageAccessible && (
        <>
          <Label>{t('Vehicle is garage accessible')}</Label>
          <Paragraph spacing="sm">{garageAccessible}</Paragraph>
        </>
      )}
      {breakdownOption !== BreakdownOptions.PARKINGGARAGE && accessible && (
        <>
          <Label>{t('Vehicle is accessible')}</Label>
          <Paragraph spacing="sm">{accessible}</Paragraph>
        </>
      )}

      {hasDispatched && (
        <>
          <Label>{t('labels.dispatchServices')}</Label>
          <Paragraph spacing="sm">
            {tow && (
              <>
                {t('labels.tow')}
                <br />
              </>
            )}
            {exchange && (
              <>
                {t('labels.exchange')}
                <br />
              </>
            )}
            {recovery && (
              <>
                {t('labels.recovery')}
                <br />
              </>
            )}
            {ldr && (
              <>
                {t('labels.ldr')}
                <br />
              </>
            )}
          </Paragraph>
        </>
      )}
    </>
  )
}

export default AccidentEvent
