// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import * as React from 'react'
import { Form, useFormState, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import * as Yup from 'yup'
import Button from 'components/Button'
import { SelectOption } from 'types/global'
import { Error } from 'components/fields'
import Input from 'components/Input'
import { validateWithYup } from 'components/FormManager/utils'
import AdvancedSearch from './AdvancedSearch'
import { Flex } from '../../components/primitives'
import { motion } from 'framer-motion'
import { MdKeyboardArrowRight } from 'react-icons/md'
import usePendoCallId from 'hooks/pendo/usePendoCallId'

interface RawFormValues {
  ticketOrResNumber?: string
  licensePlate?: string
  licensePlateState?: SelectOption
  vinLast8?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  dateOfBirth?: string
  membershipNumber?: string
  searchClosed?: boolean
}

export type TicketSearchFormValues = Omit<RawFormValues, 'licensePlateState'> & {
  licensePlateState?: string
  ticketStatus?: string
  daysToSearchPast?: number
  daysToSearchFuture?: number
}

type Props = {
  onSearchByRAOrRes: (ticketOrResNumber: string) => Promise<void>
  onSearchByTerms: (values: TicketSearchFormValues) => Promise<void>
  errorMessage?: string
  searchStatusMessage?: string
  previousSearchObject?: any
  setPreviousSearchObject?: any
  showPreviousResults?: any
  setShowPreviousResults?: any
}

const SearchStatusMessage = styled(Error.Message as any)`
  color: ${palette('calypso')};
`

const focusOnError = createFocusDecorator()

const TicketSearchForm: React.FC<Props> = ({
  onSearchByRAOrRes,
  onSearchByTerms,
  errorMessage,
  searchStatusMessage,
  previousSearchObject,
  setPreviousSearchObject,
  showPreviousResults,
  setShowPreviousResults,
}) => {
  const tabsContextStore = Ariakit.useTabContext()
  const advancedSearchStore = Ariakit.useDisclosureStore()
  const isAdvancedSearchOpen = advancedSearchStore.useState('open')
  const activeTabId = tabsContextStore?.useState('selectedId')
  const { heroPendoCallId } = usePendoCallId()

  return (
    <Ariakit.DisclosureProvider store={advancedSearchStore}>
      <Form
        onSubmit={async (values) => {
          const {
            ticketOrResNumber,
            licensePlate,
            licensePlateState,
            vinLast8,
            firstName,
            lastName,
            phoneNumber,
            dateOfBirth,
            membershipNumber,
            searchClosed,
          } = values as RawFormValues
          if (ticketOrResNumber) {
            await onSearchByRAOrRes(ticketOrResNumber.replace(/\W/g, ''))
          }
          if (activeTabId === 'tab-1') {
            const vehicle = transformVehicleValues(values)
            await onSearchByTerms(vehicle)
          }
          if (activeTabId === 'tab-2') {
            const customer = transformCustomerValues(values)
            await onSearchByTerms(customer)
          }
          const pendoEventDetails = {
            heroSearchRaResNumber: !!ticketOrResNumber ? 'Y' : 'N',
            heroSearchLicensePlate: !!licensePlate ? 'Y' : 'N',
            heroSearchLicensePlateState: !!licensePlateState ? 'Y' : 'N',
            heroSearchVinLast8: !!vinLast8 ? 'Y' : 'N',
            heroSearchFirstName: !!firstName ? 'Y' : 'N',
            heroSearchLastName: !!lastName ? 'Y' : 'N',
            heroSearchPhoneNumber: !!phoneNumber ? 'Y' : 'N',
            heroSearchDateOfBirth: !!dateOfBirth ? 'Y' : 'N',
            heroSearchMembershipNumber: !!membershipNumber ? 'Y' : 'N',
            heroSearchClosed: !!searchClosed ? 'Y' : 'N',
            heroCallId: heroPendoCallId || 'N',
          }
          ;(window as any).pendo?.track?.('Hero - Ticket Search Event', pendoEventDetails)
        }}
        decorators={[focusOnError]}
        subscription={{
          submitting: true,
        }}
        validate={validateSearch(isAdvancedSearchOpen, activeTabId!)}
      >
        {({ handleSubmit, submitting }) => (
          <SearchForm
            handleSubmit={handleSubmit}
            showPreviousResults={showPreviousResults}
            setShowPreviousResults={setShowPreviousResults}
            errorMessage={errorMessage}
            searchStatusMessage={searchStatusMessage}
            submitting={submitting}
            previousSearchObject={previousSearchObject}
            setPreviousSearchObject={setPreviousSearchObject}
          />
        )}
      </Form>
    </Ariakit.DisclosureProvider>
  )
}

type SearchProps = {
  errorMessage?: string
  searchStatusMessage?: string
  submitting: boolean
  previousSearchObject?: any
  setPreviousSearchObject?: any
  showPreviousResults?: any
  setShowPreviousResults?: any

  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<any | undefined> | undefined
}

const SearchForm: React.FC<SearchProps> = ({
  handleSubmit,
  submitting,
  errorMessage,
  searchStatusMessage,
  previousSearchObject,
  setPreviousSearchObject,
  showPreviousResults,
  setShowPreviousResults,
}) => {
  const { dirty } = useFormState()
  const form = useForm()
  const { t } = useTranslation()
  const advancedSearchStore = Ariakit.useDisclosureContext()
  const advancedSearchStoreIsOpen = advancedSearchStore?.useState('open') || false

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input style={{ textTransform: 'uppercase' }} name="ticketOrResNumber" placeholder={t('Enter RA or Res Number')} />
      <div style={{ margin: '0' }}>
        {previousSearchObject.wasPreviousSearch && (
          <Flex
            style={{
              float: 'right',
              fontSize: '14px',
              textDecoration: 'underline',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setShowPreviousResults(!showPreviousResults)}
          >
            {t('Show Previous Results')}
            <motion.div animate={{ rotate: showPreviousResults ? 0 : 180 }}>
              <MdKeyboardArrowRight size={24} />
            </motion.div>
          </Flex>
        )}
        <AdvancedSearch />
      </div>
      <div style={{ marginTop: 16 }}>
        {errorMessage && <Error.Message>{errorMessage}</Error.Message>}
        {searchStatusMessage && <SearchStatusMessage>{searchStatusMessage}</SearchStatusMessage>}
      </div>
      <Button.Primary
        loading={submitting || !!searchStatusMessage}
        type="submit"
        style={{ width: '100%', overflow: 'hidden', height: 36 }}
        onClick={() => {
          if (advancedSearchStoreIsOpen) {
            previousSearchObject.wasPreviousSearch = false
            setPreviousSearchObject(previousSearchObject)
          }
        }}
      >
        {t('Search')}
      </Button.Primary>
      <Button.Primary
        disabled={!dirty || submitting}
        style={{ width: '100%', overflow: 'hidden', height: 36, marginTop: '1rem' }}
        onClick={() => {
          form.reset()
        }}
      >
        {t('Reset')}
      </Button.Primary>
    </form>
  )
}

const raSchema = Yup.object().shape({
  ticketOrResNumber: Yup.string().required('Please enter a valid RA or Res Number'),
})

const vehicleSchema = Yup.object().shape(
  {
    ticketOrResNumber: Yup.string().notRequired(),
    licensePlate: Yup.string().when(['ticketOrResNumber', 'vinLast8'], ([ticketOrResNumber, vinLast8]: string[]) => {
      if (!(ticketOrResNumber || vinLast8)) return Yup.string().required('Please enter a valid Plate Number')
      return Yup.string().nullable().notRequired()
    }),
    licensePlateState: Yup.object()
      .nullable() // needed for react-select clearable
      .when(['ticketOrResNumber', 'vinLast8'], ([ticketOrResNumber, vinLast8]: string[]) => {
        if (ticketOrResNumber || vinLast8)
          return Yup.object().shape({ label: Yup.string(), value: Yup.string() }).nullable().notRequired()

        return Yup.object()
          .shape({ label: Yup.string().required(), value: Yup.string().required('Please enter a valid Plate State') })
          .nullable()
          .required('Please enter a valid Plate State')
      }),
    vinLast8: Yup.string().when(
      ['ticketOrResNumber', 'licensePlate', 'licensePlateState'],
      ([ticketOrResNumber, licensePlate, licensePlateState]: string[]) => {
        if (!ticketOrResNumber && !(licensePlate || licensePlateState))
          return Yup.string().required('Please enter a valid VIN Number')
        return Yup.string().nullable().notRequired()
      },
    ),
  },
  // specify these fields depend on each other
  [
    ['licensePlate', 'vinLast8'],
    ['licensePlateState', 'vinLast8'],
  ],
)

export const customerSchema = Yup.object().shape(
  {
    ticketOrResNumber: Yup.string().notRequired(),
    membershipNumber: Yup.string().notRequired(),
    firstName: Yup.string().when(
      ['ticketOrResNumber', 'membershipNumber', 'phoneNumber'],
      ([ticketOrResNumber, membershipNumber, phoneNumber]: string[]) => {
        if (!(ticketOrResNumber || membershipNumber || phoneNumber)) return Yup.string().min(3).required('First name required')
        return Yup.string().notRequired()
      },
    ),
    lastName: Yup.string().when(
      ['ticketOrResNumber', 'membershipNumber', 'phoneNumber'],
      ([ticketOrResNumber, membershipNumber, phoneNumber]: string[]) => {
        if (!(ticketOrResNumber || membershipNumber || phoneNumber)) return Yup.string().min(3).required('Last name required')
        return Yup.string().notRequired()
      },
    ),
    phoneNumber: Yup.string().notRequired(),
    dateOfBirth: Yup.string().notRequired(),
  },
  // specify circular dependencies
  [['phoneNumber', 'dateOfBirth']],
)

const validateSearch = (showAdvanced: boolean, tab: string) => (values: object) => {
  if (showAdvanced && tab === 'tab-1') return validateWithYup(vehicleSchema)(values)
  if (showAdvanced && tab === 'tab-2') return validateWithYup(customerSchema)(values)
  return validateWithYup(raSchema)(values)
}

export const transformVehicleValues = (values: RawFormValues) => {
  const { licensePlateState, licensePlate, vinLast8, searchClosed } = values

  if (licensePlateState && licensePlateState.value) {
    return {
      licensePlate: licensePlate && licensePlate.toUpperCase(),
      licensePlateState: licensePlateState.value,
      searchClosed: searchClosed,
    } as TicketSearchFormValues
  }

  return {
    vinLast8: vinLast8 && vinLast8.toUpperCase(),
    searchClosed: searchClosed,
  } as TicketSearchFormValues
}

export const transformCustomerValues = (values: RawFormValues): TicketSearchFormValues => {
  const { firstName, lastName, phoneNumber, dateOfBirth, membershipNumber, searchClosed } = values
  if (membershipNumber) {
    return {
      membershipNumber,
      ticketStatus: searchClosed ? 'CLOSED,CLOSEPENDED' : 'OPEN',
    }
  }
  let customer = {}

  customer = {
    ...customer,
    ticketStatus: searchClosed ? 'CLOSED,CLOSEPENDED' : 'OPEN',
  }

  if (phoneNumber) {
    customer = {
      ...customer,
      phoneNumber: phoneNumber.replace(/\D/g, ''),
    }
  }

  if (firstName) customer = { ...customer, firstName }

  if (lastName) customer = { ...customer, lastName }

  if (dateOfBirth) {
    const digits = dateOfBirth.split('/')
    if (digits.length === 3) {
      customer = {
        ...customer,
        dateOfBirth: `${digits[2]}-${digits[0]}-${digits[1]}`,
      }
    }
  }

  return customer
}

export default TicketSearchForm
