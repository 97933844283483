import { Exchange } from 'types/callstore'
import { FormattedCaller } from 'components/fields/Submit'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { MailData, ExchangeEmailInput, Recipients, getEmailRecipients } from '../emails'
import Handlebars from 'handlebars'
import { useServiceProxy } from '../kong'
import { config } from '../../config'
import { BranchInfoFragment } from 'types/branch'

export type ExchangeEmailValues = Exchange & Omit<ExchangeEmailInput, 'rentalGPBR' | 'rentalStationId' | 'rentalBrand' | 'vin'>

export const useExchangeEmail = () => {
  const { ticketNumber, pickupInfo, vehicle } = useSelector(selectTicket)
  const serviceProxy = useServiceProxy()
  const currentUser = useSelector(selectCurrentUser)
  const { noReplyExchangeEmailsCsv, rentalAgreementUrn } = config
  const agentEID = currentUser!.nameID
  return async (exchange: ExchangeEmailValues, caller: FormattedCaller) => {
    const pickupBranch = pickupInfo.branch as BranchInfoFragment
    const {
      accommodationNotes,
      branch,
      disabledVehicle,
      exchangeNotes,
      methodOfDelivery,
      modified,
      towLocation,
      vehicleAccommodationSelect,
      vehicleCondition,
    } = exchange

    const emailVariables = {
      vehicleAccommodation: vehicleAccommodationSelect.label,
      accommodationNotes,
      name: caller.callerName,
      ticketNumber,
      phone: caller.formatted,
      modified,
      exchangeGPBR: branch?.additional_data?.group_branch_number,
      exchangeBrand: branch?.brand,
      peopleSoftId: branch?.id,
      rentalGPBR: pickupBranch.groupBranchNumber,
      transportationMethod: methodOfDelivery,
      rentalVehicle: `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`,
      vin: vehicle!.vinLast8,
      description: exchangeNotes,
      vehicleCondition,
      disabledVehicle,
      towLocation,
      agentEID,
    }

    if (!emailVariables.transportationMethod) {
      emailVariables.transportationMethod = 'Drive-In'
    }
    if (!emailVariables.towLocation || emailVariables.transportationMethod === 'Drive-In') {
      emailVariables.disabledVehicle = 'Problem vehicle will be with the customer'
    }

    const title = emailVariables.modified
      ? 'Modified - Exchange Vehicle Pickup Notification'
      : 'Exchange Vehicle Pickup Notification'

    const htmlFile = require('emails/exchangeEmail.html').default
    const compileHandlebars = Handlebars.compile(htmlFile)
    const data = {
      ...emailVariables,
      title,
    }

    const emailData = {
      to: Recipients.EXCHANGE(data),
      from: noReplyExchangeEmailsCsv,
      subject: title,
      html: compileHandlebars(data),
      peopleSoftId: emailVariables.peopleSoftId,
      ticketNumber: emailVariables.ticketNumber,
    }
    return await serviceProxy<MailData>(
      'post',
      `/serviceproxy/email`,
      { 'ehi-device-location-id': emailData.peopleSoftId },
      {
        type: 'HTML',
        context: rentalAgreementUrn + emailData.ticketNumber,
        sender: {
          address: emailData.from,
          displayName: 'Roadside Assistance',
        },
        subject: emailData.subject,
        message: emailData.html,
        to: [...getEmailRecipients(emailData.to)],
      },
    )
  }
}

export default useExchangeEmail
