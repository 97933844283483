import * as React from 'react'
import { Field } from 'react-final-form'
import { pxToRem } from 'theme/utils'
import CallTypeRadio from './CallTypeRadio'
import { TicketStatus } from 'types/ticket'
import { CallTypes } from 'types/callstore'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

// Call types available if ticket has no current vehicle
const reducedCallTypes = [
  CallTypes.RECOVERY,
  CallTypes.LDR,
  CallTypes.LAW,
  CallTypes.FEATURE,
  CallTypes.DROP,
  CallTypes.TRANSFER,
  CallTypes.CALLBACK,
]

const callTypeValues = Object.values(CallTypes).filter((ct) => ct !== CallTypes.ADDITIONAL && ct !== CallTypes.GUIDED_ASSIST)
const reducedCallValues = Object.values(reducedCallTypes)

export function CallTypeSelection() {
  const { ticketStatus, vehicle } = useSelector(selectTicket)

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${pxToRem(44)}, 1fr))`,
          gridGap: `${pxToRem(12)} ${pxToRem(24)}`,
        }}
      >
        {callTypeValues.map((type, index) => {
          if (type === CallTypes.LAW) return null
          if (type === CallTypes.SPARE_TIRE_CHANGE) return null
          const disabled =
            (ticketStatus === TicketStatus.CLOSED ||
              ticketStatus === TicketStatus.CLOSEPENDED ||
              ticketStatus === TicketStatus.CANCELLED) &&
            !reducedCallValues.includes(type)
          return (
            <Field key={index} name="callType" type="radio" value={type} component={CallTypeRadio as any} disabled={disabled} />
          )
        })}
      </div>
    </>
  )
}

export default CallTypeSelection
