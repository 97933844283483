import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { useVehicleLocation } from 'hooks/redux/useLocations'
import { Label, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import Button from 'components/Button'
import ServiceLocationDisplay from 'components/Dispatch/ServiceLocationDisplay'
import {
  ConfirmedExchangeLocation,
  ConfirmedParticipantLocation,
  ConfirmedVehicleLocation,
} from 'components/Dispatch/ConfirmedLocations'
import Exchange, { ExchangeBranchDisplay } from 'components/Exchange'
import Input from 'components/Input'
import SmartAssignment from 'components/SmartAssignment'
import Location from 'components/Input/Location'
import { Step } from 'components/Step'
import { DynamoEvents, EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events'
import { LocationState, ServiceLocationType } from 'types/location'
import { ICurrentEvent } from 'types/events'
import { Branch } from 'types/branch'

type Props = {
  dispatchEvent?: DynamoEvents
}

export default function TowSteps({ dispatchEvent }: Props) {
  const { t } = useTranslation()
  const vehicleLocation = useVehicleLocation()
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()
  const towEvent = useCallEvent(EventTypes.TOW) as ICurrentEvent
  const event = dispatchEvent || towEvent.event

  if (!event || !event.attributes) return null
  const {
    rideAlong,
    customerSupplied,
    serviceLocations,
    agentEID,
    branch,
    vehicle,
    network,
    exchangeNotes,
    vehicleAccommodationSelect,
    accommodationNotes,
    disabledVehicle,
    bypassReason,
  } = event?.attributes

  return (
    <>
      <>
        <Label>{t('labels.customersTransportationMethod')}</Label>
        <Paragraph spacing="md">
          {customerSupplied ? t('Customer Supplied') : rideAlong ? t('Ride Along') : t('Third Party Transportation')}
        </Paragraph>
        {rideAlong && <Input.Hidden name="rideAlong" defaultValue={true as any} />}
        <Step step="1">
          <Location.Vehicle name={'serviceLocations.vehicleLocation'} serviceLocationType={ServiceLocationType.VEHICLE} edit />
          <Margin spacing="sm">
            <Button.Primary onClick={() => controlsContext?.move(controlsContext?.next())}>
              {t('labels.confirmDisabledVehicle')}
            </Button.Primary>
          </Margin>
          <ServiceLocationDisplay location={(serviceLocations as LocationState).vehicleDestinationLocation} network={network} />
          <ExchangeBranchDisplay
            branch={branch}
            agentEID={agentEID}
            exchangeNotes={exchangeNotes}
            vehicleAccommodation={vehicleAccommodationSelect.label}
            accommodationNotes={accommodationNotes}
            disabledVehicle={disabledVehicle}
            bypassReason={bypassReason}
          />
        </Step>
        <Step step="2">
          <Margin spacing={'md'}>
            <ConfirmedVehicleLocation />
            <Input.Hidden name="vehicle" defaultValue={vehicle as any} />
            <SmartAssignment
              onSetAssignment={(participant) => {
                form.change('serviceLocations.vehicleDestinationLocation', {
                  ...participant,
                  serviceLocationType: ServiceLocationType.VEHICLEDESTINATION,
                })
                controlsContext?.move(controlsContext?.next())
              }}
            />
            <ExchangeBranchDisplay
              branch={branch}
              agentEID={agentEID}
              exchangeNotes={exchangeNotes}
              vehicleAccommodation={vehicleAccommodationSelect.label}
              accommodationNotes={accommodationNotes}
              disabledVehicle={disabledVehicle}
              bypassReason={bypassReason}
            />
          </Margin>
        </Step>
        <Step step="3">
          <ConfirmedVehicleLocation />
          <ConfirmedParticipantLocation />
          <Exchange
            name="branch"
            isTowExchange={true}
            searchLocation={vehicleLocation}
            onSetBranch={(newBranch) => {
              const exchangeBranch = newBranch as Branch
              if (rideAlong) {
                const { brand } = exchangeBranch
                const addressLines = exchangeBranch?.address?.street_addresses[0]
                const formattedCityStateZip = exchangeBranch?.additional_data?.formatted_city_state_zip
                form.change('rideAlongLocation', `${brand}, ${addressLines[0]}, ${formattedCityStateZip}`)
              }

              controlsContext?.move(controlsContext?.next())
            }}
          />
        </Step>
        <Step step="4">
          <ConfirmedVehicleLocation />
          <ConfirmedParticipantLocation />
          <ConfirmedExchangeLocation />
        </Step>
      </>
    </>
  )
}
