import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import { EventTypes } from 'types/events'
import { Tow, TowExchange } from 'types/callstore'
import { useCreateServiceEvent } from 'hooks/events'
import { FormattedCaller } from 'components/fields/Submit'
import { useAAAStandardServices, useTowExchangeEmail } from 'hooks/aaa'
import useUpdateAssignment from 'hooks/assignment/useUpdateAssignment'
import useUpdateTicketCharges from 'hooks/tickets/useUpdateTicketCharges'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectFormsState, selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { StandardServiceCall } from 'types/aaa'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { createExchangeFromTowEvent } from 'utils/exchange'
import { BranchInfoFragment } from 'types/branch'
import { pruneAAADispatch } from 'utils/aaa'

export const useCreateTowService = (eventType?: EventTypes) => {
  const { t } = useTranslation()
  const ticket = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)
  const { additionalService } = useSelector(selectFormsState) as any
  const updateAssignment = useUpdateAssignment()
  const events = [EventTypes.TOW, additionalService].filter(Boolean) as ReadonlyArray<EventTypes>
  const createServiceRequests = useAAAStandardServices(events)
  const createServiceEvent = useCreateServiceEvent({
    eventType: EventTypes.TOW,
  })
  const createExchangeServiceEvent = useCreateServiceEvent({
    eventType: EventTypes.EXCHANGE,
  })
  const createSoftServiceEvent = useCreateServiceEvent({
    eventType: additionalService || EventTypes.DISPATCH,
  })
  const sendTowExchangeEmail = useTowExchangeEmail()
  const updateTicketCharges = useUpdateTicketCharges()
  const callStore = useSelector(selectFormsState) as any
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()

  return async (state: FormState<Tow | TowExchange>, caller: FormattedCaller) => {
    const service = state.values as typeof eventType extends undefined ? Tow : TowExchange
    const lockoutNotes = 'Please perform Lockout service with Tow. ' + service.notes
    const winchNotes = 'Please perform Winch service with Tow. ' + service.notes
    const additionalNotes = additionalService === 'lockout' ? lockoutNotes : additionalService === 'winch' ? winchNotes : ''

    const unattendedNotes = service.unattended ? ' Vehicle is unattended and locked.' : ''

    const serviceWithAdditionalNotes = {
      ...service,
      notes: additionalNotes + service.notes + unattendedNotes,
    }

    try {
      const dispatchData: any[] = []
      const serviceRequests = createServiceRequests(serviceWithAdditionalNotes, caller)
      // Fire Tow Dispatch
      const { data: towData } = await serviceProxy<StandardServiceCall>(
        'post',
        `/serviceproxy/aaa/dispatch/`,
        {},
        serviceRequests[0],
      )
      dispatchData.push(towData)

      if (Object.keys(service.serviceLocations).length === 4 && serviceRequests.length > 1) {
        const { data: exchangeData } = await serviceProxy<StandardServiceCall>(
          'post',
          `/serviceproxy/aaa/dispatch/`,
          {},
          serviceRequests[1],
        )
        dispatchData.push(exchangeData)
      }

      // Create DynamoDB Events
      if (dispatchData.length > 0) {
        // Optionally charge ticket, update assignment, send exchange email
        if (additionalService) {
          await updateTicketCharges(additionalService)
        }

        await updateAssignment(state.values)

        if (eventType === EventTypes.EXCHANGE) {
          const towExchangeValues = state.values as TowExchange
          const pickupBranch = ticket.pickupInfo.branch as BranchInfoFragment

          const exchangeEvent = await createExchangeServiceEvent({
            ...createExchangeFromTowEvent(towExchangeValues),
            rentalBrand: ticket.brandId,
            rentalGPBR: pickupBranch.groupBranchNumber,
            rentalStationId: pickupBranch.stationId,
            ticketNumber: ticket.ticketNumber,
            vinLast8: ticket.vehicle!.vinLast8,
            agentEID: currentUser?.nameID,
          })

          await sendTowExchangeEmail(state.values as TowExchange, caller)
          dispatch(
            setCurrentEvent({
              eventType: EventTypes.EXCHANGE,
              newEvent: exchangeEvent.data,
            }),
          )
        }

        // Check for soft service with Tow
        const softService = dispatchData.find(isSoftService)
        if (softService && additionalService) {
          // @ts-ignore - Lockout, Winch are defined on CallStore by CallStore property = EventTypes
          const softServiceFormValues = callStore[additionalService]

          if (softService) {
            const prunedDispatch = pruneAAADispatch(softService)
            const event = await createSoftServiceEvent({
              ...softServiceFormValues,
              dispatch: [prunedDispatch],
              customerOptIn: caller.optIn || softService.optIn || false,
            })
            dispatch(
              setCurrentEvent({
                eventType: additionalService || EventTypes.DISPATCH,
                newEvent: event.data,
              }),
            )
          }
        }

        // Save tow dispatches with Tow event
        const tows = dispatchData.filter(isTowService)

        if (tows.length > 1) {
          const { branchTowRequest } = splitTwoWayFormValues(serviceWithAdditionalNotes)
          const prunedTowDispatch = pruneAAADispatch(tows[0])

          const event = await createServiceEvent(
            {
              ...branchTowRequest,
              dispatch: [prunedTowDispatch],
              customerOptIn: caller.optIn || tows[0]?.optIn || false,
            },
            tows[0].callKey,
          )

          const prunedTowTwoDispatch = pruneAAADispatch(tows[1])
          await createServiceEvent(
            {
              ...serviceWithAdditionalNotes,
              dispatch: [prunedTowTwoDispatch],
              customerOptIn: caller.optIn || tows[1]?.optIn || false,
            },
            tows[1].callKey,
          )
          dispatch(
            setCurrentEvent({
              eventType: EventTypes.TOW,
              newEvent: { ...event.data, attributes: { ...event.data.attributes, ...serviceWithAdditionalNotes } },
            }),
          )
        } else {
          const prunedTowDispatch = pruneAAADispatch(tows[0])
          const event = await createServiceEvent(
            {
              ...serviceWithAdditionalNotes,
              dispatch: [prunedTowDispatch],
              customerOptIn: caller.optIn || tows[0]?.optIn || false,
            },
            tows[0].callKey,
          )
          dispatch(setCurrentEvent({ eventType: EventTypes.TOW, newEvent: event.data }))
        }
      }
    } catch (e) {
      console.error(e)
      return t('error.aaa')
    }
  }
}

// Assert dispatch type functions.  Soft services have only one serviceLocation
const isSoftService = (dispatch?: StandardServiceCall) => dispatch && dispatch.service.serviceLocations.length === 1
const isTowService = (dispatch?: StandardServiceCall) => dispatch && !isSoftService(dispatch)

const splitTwoWayFormValues = (service: TowExchange) => {
  const {
    serviceLocations,
    agentEID,
    customerWithVehicle,
    network,
    unattended,
    keysLocation,
    keysLocationOther,
    vehicleCondition,
    notes,
    dispatchRentalNotes,
    ...rest
  } = service

  const branchTowRequest = {
    serviceLocations: { exchangeLocation: serviceLocations.exchangeLocation, callerLocation: serviceLocations.callerLocation },
    agentEID,
    customerWithVehicle,
    notes: dispatchRentalNotes,
    ...rest,
  }

  const vehicleTowRequest = {
    serviceLocations: [serviceLocations.vehicleDestinationLocation, serviceLocations.vehicleLocation],
    network,
    unattended,
    keysLocation,
    keysLocationOther,
    vehicleCondition,
    notes,
    ...rest,
  }

  return {
    branchTowRequest,
    vehicleTowRequest,
  }
}

export default useCreateTowService
