import * as React from 'react'
import { MdPlayArrow } from 'react-icons/md'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { pxToRem } from 'theme/utils'
import { palette } from 'styled-tools'
import { toggleSidebar } from 'redux/slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState, selectSidebarState } from 'redux/appStore'
import { SidebarPosition } from 'types/global'

type Props = { side: SidebarPosition }

const ToggleButton = styled(Flex as any)<Props>`
  justify-content: center;
  align-items: center;
  z-index: 10001;
  margin-top: ${pxToRem(16)};
  height: ${pxToRem(40)};
  position: absolute;
  cursor: pointer;
  padding: ${pxToRem(8)} 0;
  background-color: ${palette('wildSand')};

  ${({ side }) => side === SidebarPosition.RIGHT && 'right: 0;'}
`

const SidebarToggleButton = ({ side }: Props) => {
  const dispatch = useDispatch()
  const isSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)(side))
  const toggle = React.useCallback(() => {
    dispatch(toggleSidebar({ side, toggle: isSidebarOpen ? 'CLOSE' : 'OPEN' }))
  }, [dispatch, side, isSidebarOpen])

  const [from, to] = side === SidebarPosition.LEFT ? [180, 0] : [0, 180]

  return (
    <ToggleButton onClick={toggle} side={side}>
      <MdPlayArrow size={16} style={{ transform: `rotate(${isSidebarOpen ? from : to}deg)` }} />
    </ToggleButton>
  )
}

export default SidebarToggleButton
