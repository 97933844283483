import { useDispatch, useSelector } from 'react-redux'
import { ServiceProxyCall } from 'types/global'
import { FleetVinRequest } from 'types/kong'
import { deepMapSanitize } from 'utils/deepMap'
import { useServiceProxy } from './useServiceProxy'
import { useEffect, useState } from 'react'
import { selectCustomVinInput, selectTicket } from 'redux/appStore'
import { isNil } from 'lodash-es'
import { updateTicketVehicle } from 'redux/ticket/ticketSlice'
import { TransformedVehicle } from 'types/ticket'

// Retrieve Vehicle Fleet Details ( fuelCategory, vin, telematics, rental status )
async function searchByFleetVehicleSearch(vinNumber: string, proxy: ServiceProxyCall) {
  const headers = {}
  const requestBody = { fleetVehicle: { vehicleAsset: { vins: [vinNumber] } } }

  return proxy<FleetVinRequest>('post', `/serviceproxy/fleetVehicle/search`, headers, requestBody).then((response) => ({
    ...response,
    data: deepMapSanitize(response?.data) as FleetVinRequest,
  }))
}

export function useFleetVinSearch() {
  const ticket = useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()

  const [loadingVehicle, setLoadingVehicle] = useState(false)
  const [vehicleError, setVehicleError] = useState<any>(null)

  console.log({ vehicleError })
  useEffect(() => {
    async function getFleetVehiclePhysicalDetails(vinNumber: string) {
      setLoadingVehicle(true)
      setVehicleError(null)
      const fleetVehicleDetails = await searchByFleetVehicleSearch(vinNumber, serviceProxy).finally(() =>
        setLoadingVehicle(false),
      )

      if (fleetVehicleDetails?.data === undefined || isNil(fleetVehicleDetails.data?.fleetVehicles)) {
        setVehicleError('No Vehicles Found')
      }

      if (fleetVehicleDetails.data && fleetVehicleDetails.data.fleetVehicles) {
        const urn = fleetVehicleDetails.data.fleetVehicles[0].urn.split(':')
        const unitNumber = urn[urn.length - 1]
        const vehicleAsset = fleetVehicleDetails.data.fleetVehicles[0].vehicleAsset
        const updatedVehicle = {
          make: vehicleAsset.specification.make.name,
          model: vehicleAsset.specification.model.name,
          year: vehicleAsset.specification.year,
          color: vehicleAsset.color.exteriorBaseColor,
          vin: vehicleAsset.vin,
          vinLast8: vehicleAsset.vin.slice(-8),
          sippCode: vehicleAsset.specification.sippCode,
          unitNumber: unitNumber,
          licensePlate: vehicleAsset.registrationPlate.number,
          licensePlateState: vehicleAsset.registrationPlate.jurisdiction.countrySubdivision,
        } as TransformedVehicle
        dispatch(updateTicketVehicle({ vehicle: updatedVehicle || {} }))
        setVehicleError(null)
      }
    }

    if (
      (ticket && isNil(ticket.vehicle) && !isNil(customVinInput)) ||
      (!isNil(customVinInput) && customVinInput !== ticket.vehicle?.vin)
    ) {
      getFleetVehiclePhysicalDetails(customVinInput)
    }
  }, [ticket, customVinInput])

  return { loading: loadingVehicle, error: vehicleError }
}
