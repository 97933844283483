import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import useLocations, { useCallerLocation } from 'hooks/redux/useLocations'
import { EventTypes } from 'types/events'
import { Field as FieldValue } from 'react-final-form'
import Button from 'components/Button'
import { Heading } from 'components/primitives'
import { YesNoRadio, YesNoTriggers } from 'components/Input'
import Margin from 'components/Margin'
import SmartAssignment from 'components/SmartAssignment'
import Exchange from 'components/Exchange'
import DispatchTow from './DispatchTow'
import { WorkflowAction } from 'components/Workflow'
import { CallTypes, TowExchange } from 'types/callstore'
import { Step, BackNavigation } from 'components/Step'
import Location from 'components/Input/Location'
import { VehicleSelect } from 'components/Select'
import {
  setSmartAssignmentParticipant,
  setSmartAssignmentCustomDropoffDestination,
} from 'redux/smartAssignment/smartAssignmentSlice'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import Input from 'components/Input'
import { useCallEvent } from 'hooks/events'
import VehicleCondition from 'components/VehicleCondition'
import { Condition } from 'components/fields'
import { isRequired } from 'utils/validators'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState, selectTicket } from 'redux/appStore'
import { ServiceLocationType } from 'types/location'
import { updateLocation } from 'redux/location/locationSlice'

const additionalIcon = () => <CallTypeIcon callType={CallTypes.ADDITIONAL} gutter="sm" />

// 0 - Exchange
// 1 - Caller
// 2 - Vehicle
// 3 - Tow Destination

const DirectionBranch: React.FC = () => {
  const { t } = useTranslation()
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()
  const dispatch = useDispatch()
  const callerLocation = useCallerLocation()
  const recoveryEvent = useCallEvent(EventTypes.RECOVERY)
  const { additionalService } = useSelector(selectFormsState) as any
  const { vehicle } = useSelector(selectTicket)
  const { year, make, model, licensePlate, licensePlateState, vin, color } = vehicle || {}

  const locations = useLocations()
  const filteredLocations = Object.entries(locations)
    .filter(([key, value]) => value !== undefined && key !== 'exchangeLocation')
    .reduce((obj, [key, value]) => {
      obj[key] = value
      return obj
    }, {})

  React.useEffect(() => {
    form.change('type', 'towExchange')
  }, [form])

  return (
    <>
      <Step step="1">
        <BackNavigation
          onClick={() => {
            form.change('serviceLocations', filteredLocations)
            form.change('towDirection', null)
            form.change('type', null)
            form.change('customerSupplied', null)
          }}
        />
        <Heading as="h5" spacing="md">
          {t('tow.exchange.exchangeBranchPickup')}
        </Heading>
        <Margin spacing="md">
          <Location.Caller name="serviceLocations.callerLocation" label={t('labels.deliverNewVehicle')} required />
        </Margin>
        {callerLocation && (
          <Exchange
            name="serviceLocations.exchangeLocation"
            searchLocation={callerLocation}
            isTowExchange={true}
            onSetBranch={() => {
              controlsContext?.move(controlsContext?.next())
            }}
          />
        )}
      </Step>
      <Step step="2">
        <BackNavigation
          onClick={() => {
            controlsContext?.move(controlsContext?.previous())
          }}
        />
        <Heading as="h5" spacing="md">
          {t('tow.exchange.selectOne')}
        </Heading>
        <Margin spacing="md">
          <WorkflowAction
            callType={CallTypes.TOW}
            eventType={EventTypes.TOW}
            label={t('tow.exchange.towDisabledVehicle')}
            onClick={() => {
              form.change('disabledVehiclePickup', true)
              controlsContext?.move('4')
            }}
            disabled={!!recoveryEvent}
            required
          />
          <Input.Button
            label={t('tow.exchange.deliveryOnly')}
            name={'disabledVehiclePickup'}
            disabled={!!additionalService}
            iconBefore={additionalIcon}
            onClick={() => {
              form.change('disabledVehiclePickup', false)
              controlsContext?.move(controlsContext?.next())
            }}
            required
          />
        </Margin>
      </Step>
      {/* Step 3 is for Deliver Only */}
      <Step step="3">
        <Input.Hidden name="vehicle" defaultValue={{ year, make, model, licensePlate, licensePlateState, vin, color } as any} />
        <BackNavigation
          onClick={() => {
            form.change('disabledVehiclePickup', null)
            controlsContext?.move(controlsContext?.previous())
          }}
        />
        <DispatchTow />
      </Step>
      <Step step="4">
        <BackNavigation
          onClick={() => {
            form.change('disabledVehiclePickup', null)
            form.change('customerWithVehicle', null)
            const { serviceLocations } = form.getState().values as TowExchange
            // delete vehicle locations from redux to prevent reverseTwoWayTow flow if 'Tow Disabled Vehicle' was chosen first
            delete serviceLocations.vehicleLocation
            delete serviceLocations.vehicleDestinationLocation
            controlsContext?.move('2')
          }}
        />
        <Margin spacing="md">
          <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
          <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
        </Margin>
        <VehicleCondition />
        <Condition when="vehicleCondition" is={(v) => !!v}>
          <Margin spacing="md">
            <YesNoRadio name="customerWithVehicle" trigger={YesNoTriggers.NO} label={t('tow.exchange.withDisabledVehicle')}>
              <Margin spacing="md">
                <Location.Caller name="serviceLocations.callerLocation" />
              </Margin>
              <Margin spacing="md">
                <Location.Vehicle name="serviceLocations.vehicleLocation" />
              </Margin>

              <Button.Primary
                style={{ width: '100%' }}
                onClick={() => {
                  const { serviceLocations } = form.getState().values as TowExchange
                  if (serviceLocations.callerLocation) {
                    form.change('unattended', true)
                    form.change('vehicleLocationSet', true)
                    controlsContext?.move(controlsContext?.next())
                  }
                }}
              >
                {t('tow.exchange.setDisabledLocation')}
              </Button.Primary>
            </YesNoRadio>
            <FieldValue name="customerWithVehicle" subscription={{ value: true }}>
              {({ input: { value } }) => {
                if (value === YesNoTriggers.YES) {
                  const { serviceLocations } = form.getState().values as TowExchange
                  //delete vehicle location to prevent reverseTwoWayTow flow if 'No' was chosen first
                  delete serviceLocations.vehicleLocation
                  if (serviceLocations.callerLocation) {
                    dispatch(
                      updateLocation({
                        name: 'vehicleLocation',
                        location: { ...serviceLocations.callerLocation!, serviceLocationType: 'VEHICLE' },
                      }),
                    )
                  }
                  controlsContext?.move(controlsContext?.next())
                }
                return null
              }}
            </FieldValue>
          </Margin>
        </Condition>
      </Step>
      <Step step="5">
        <SmartAssignment
          onSetAssignment={(participant) => {
            const { serviceLocations } = form.getState().values
            form.change('serviceLocations.vehicleDestinationLocation', {
              ...serviceLocations.vehicleDestinationLocation,
              ...participant,
              serviceLocationType: ServiceLocationType.TOW_DESTINATION,
            })
            controlsContext?.move(controlsContext?.next())
          }}
          exitOnClick={() => {
            form.change('vehicleLocationSet', null)
            form.change('unattended', null)
            form.change('customerWithVehicle', null)
            controlsContext?.move(controlsContext?.previous())
          }}
        />
      </Step>
      <Step step="6">
        <BackNavigation
          onClick={() => {
            dispatch(setSmartAssignmentParticipant(null))
            dispatch(setSmartAssignmentCustomDropoffDestination(null))
            controlsContext?.move(controlsContext?.previous())
          }}
        />
        <DispatchTow />
      </Step>
    </>
  )
}

export default DirectionBranch
