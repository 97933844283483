import * as React from 'react'
import { useFormState } from 'react-final-form'
import { Label, Paragraph } from 'components/primitives'
import SmartAssignment from 'components/SmartAssignment'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { YesNoTriggers } from 'components/Input'
import { LocationLabels } from 'components/Input/Location'
import { isLocation, isParticipant, isBranch } from 'utils/typeChecks'
import IncidentLocation from 'components/IncidentLocation'
import { LocationPayload, LocationState, ServiceLocationType } from 'types/location'
import { Branch } from 'types/branch'

export const DirectionBranchInfo = () => {
  const { t } = useTranslation()
  const state = useFormState({ subscription: { values: true } })

  if (!state) return null
  const {
    network,
    serviceLocations,
    customerWithVehicle,
    vehicleLocationSet,
    agentEID,
    vehicle,
    customerSupplied,
    vehicleCondition,
  } = state.values
  const isWithVehicle = customerWithVehicle === YesNoTriggers.YES

  return (
    <>
      <Label>{t('labels.customersTransportationMethod')}</Label>
      <Paragraph spacing="md">{customerSupplied ? t('Customer Supplied') : t('Vehicle Delivery')}</Paragraph>
      <Margin spacing="md">
        {serviceLocations &&
          Object.values(serviceLocations as LocationState).map((location: LocationPayload | null, i: number) => {
            if (!location) return null

            // Don't show caller if it's the only location
            if (
              isLocation(location) &&
              location.serviceLocationType === ServiceLocationType.CALLER &&
              !serviceLocations.vehicleLocation
            )
              return null

            // Don't show vehicle until confirmed
            if (isLocation(location) && location.serviceLocationType === ServiceLocationType.VEHICLE && !vehicleLocationSet)
              return null

            const isLast = i === serviceLocations.length - 1

            if (isLocation(location)) {
              const label = LocationLabels[location.serviceLocationType]
              return (
                <React.Fragment key={i}>
                  {location.serviceLocationType === ServiceLocationType.VEHICLE && (
                    <>
                      <Margin spacing="md">
                        <Label>{t('labels.vehicle')}</Label>
                        <Paragraph>{vehicle?.label}</Paragraph>
                      </Margin>
                      <Margin spacing="md">
                        <Label>{t('labels.vehicleCondition')}</Label>
                        <Paragraph>{vehicleCondition}</Paragraph>
                      </Margin>
                    </>
                  )}
                  <IncidentLocation label={t(label)} location={location} spacing={isLast && !isWithVehicle ? 'none' : 'md'} />
                  {isWithVehicle && (
                    <Margin spacing="md">
                      <Label>{t('labels.vehicleLocation')}</Label>
                      <Paragraph>{t('Customer is with vehicle')}</Paragraph>
                    </Margin>
                  )}
                </React.Fragment>
              )
            }

            if (isParticipant(location)) {
              return (
                <Margin key={i} spacing={isLast ? 'none' : 'md'}>
                  <Label>{t('labels.disabledVehicleDropoff')}</Label>
                  <SmartAssignment.Participant participant={location} network={network?.value} showTooltip={false} />
                </Margin>
              )
            }

            if (isBranch(location)) {
              const branchLocation = location as Branch
              return (
                <React.Fragment key={i}>
                  <Margin spacing={isLast ? 'none' : 'md'}>
                    <Label>{t('Exchange Branch Location')}</Label>
                    <Paragraph spacing="md">
                      {branchLocation.brand} - {branchLocation.additional_data.group_branch_number}
                    </Paragraph>
                    <Paragraph spacing="md">{branchLocation.additional_data.formatted_phone}</Paragraph>
                    <Paragraph>{branchLocation.address.street_addresses[0]}</Paragraph>
                    <Paragraph>{branchLocation.additional_data.formatted_city_state_zip}</Paragraph>
                  </Margin>
                  {agentEID && (
                    <>
                      <Label>{t('labels.exchangeApproval')}</Label>
                      <Margin spacing="md">{agentEID}</Margin>
                    </>
                  )}
                </React.Fragment>
              )
            }

            return null
          })}
      </Margin>
    </>
  )
}

export default DirectionBranchInfo
