import { useEffect, useMemo } from 'react'
import { setBasePendoMetadata, initializePendoLogger, updatePendoOptions } from '@ehi/analytics'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/appStore'
import { config } from '../../config'
// import { logError } from 'common/logging/splunkLogger';
import axios from 'axios'
import { msalConfig } from '../Auth/getAzureConfig'
import { useMsal } from '@azure/msal-react'
import * as React from 'react'

const PendoHandler = () => {
  const auth = useSelector(selectCurrentUser)
  const { pendoApiKey, serviceProxyUrl } = config
  const { instance, accounts } = useMsal()
  let [accessToken, setAccessToken] = React.useState('')
  useEffect(() => {
    const getAccessToken = async () => {
      const { accessToken } = await instance.acquireTokenSilent({
        scopes: msalConfig.scopes,
        account: accounts[0],
      })
      setAccessToken(accessToken)
    }

    getAccessToken()
  }, [accounts, instance])

  let endpoint = '/serviceproxy/'
  if (window.location.hostname !== 'localhost') {
    endpoint = serviceProxyUrl + '/proxy/'
  }
  const locationApiAxiosInstance = useMemo(() => {
    return axios.create({
      baseURL: endpoint,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }, [accessToken, endpoint])

  // Initialize with the pendo logger with your key, the user's id, and the user's groupBranch.
  useEffect(() => {
    initializePendoLogger(pendoApiKey, auth?.nameID || '', auth?.groupBranch || '')
  }, [auth?.groupBranch, auth?.nameID, pendoApiKey])

  // If your application has the possibility of setting / changing the location, you will want a method to get that. If not, then do not pass in the setBranchPeoplesoftId property.
  // const setBranch = getSetEhiLocation();

  // Get the current locale string for the app
  // const { i18n } = useTranslation()
  // const locale = i18n.language
  // TO DO: EU Expansion - make this locale dynamic
  const locale = 'en_US'

  setBasePendoMetadata({
    eid: auth?.nameID || '',
    userGroupBranch: auth?.groupBranch || '',
    userRole: auth?.jobTitle || '',
    locale: locale,
    locationApiAxiosInstance: locationApiAxiosInstance,
    // setBranchPeoplesoftId: setBranch.peoplesoftId,
    // TODO: add logging for Pendo errors
    //   logError: (e: unknown) => {
    //     // You can optionally pass in a function to run when there is an error setting the pendo data.
    //     // Run some custom logging here if desired
    //     logError(e);
    //   }
  })
  updatePendoOptions({
    pendoMetadata: {
      customApplicationMetadataAttributes: {
        heroRole: auth?.roles[0] || '',
      },
    },
  })
  // @ts-ignore
  return <></>
}
export default PendoHandler
