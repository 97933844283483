import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRecentDispatches, selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { CaseServiceCall, ULY_CLIENT_CODES } from 'types/urgently'
import { IncomingCaseServiceCall } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'
import { addRecentULYDispatch } from 'redux/recentDispatch/recentDispatchSlice'

export function useULYDispatchQuery(caseId?: string | null) {
  const { brandId } = useSelector(selectTicket)
  const dispatch = useDispatch()
  const dispatches = useSelector(selectRecentDispatches)
  const addRecentDispatch = (newDispatch: CaseServiceCall) => dispatch(addRecentULYDispatch(newDispatch))
  const [error, setError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<CaseServiceCall | null>(null)
  const serviceProxy = useServiceProxy()

  const shouldFireQuery = React.useRef<boolean>(false)

  const query = React.useCallback(
    async (shouldFireQuery: boolean) => {
      if (shouldFireQuery) {
        try {
          setLoading(true)
          setError(null)
          const { data } = await serviceProxy<IncomingCaseServiceCall>(
            'get',
            `/serviceproxy/urgently/${ULY_CLIENT_CODES[brandId]}/cases/${caseId}`,
          )
          const dispatchData = deepMapSanitize(data) as CaseServiceCall
          setData(dispatchData)
          setLoading(false)
        } catch (e) {
          console.error(e)
          setLoading(false)
          setError('Unable to lookup ULY dispatch')
        }
      }
    },
    [caseId, serviceProxy, brandId],
  )

  React.useEffect(() => {
    let recentDispatch = dispatches.ULYDispatches.find((disp) => disp.case.id === caseId)

    if (recentDispatch !== undefined) {
      setData(recentDispatch)
      setLoading(false)
      shouldFireQuery.current = false
    } else {
      setData(null)
      shouldFireQuery.current = true
    }

    if (data && dispatches.ULYDispatches.find((disp) => disp.case.id === data?.case.id) === undefined) {
      addRecentDispatch(data)
    }
  }, [shouldFireQuery, caseId, data, dispatches])

  React.useEffect(() => {
    async function fetchUrgentlyData() {
      if (error || loading) return

      if (caseId && data === null) {
        setLoading(true)
        await query(shouldFireQuery.current)
        shouldFireQuery.current = false
      }
    }

    shouldFireQuery && fetchUrgentlyData()
  }, [caseId, shouldFireQuery.current, data, error, loading, query])

  return { data, loading, error, refetch: query }
}

function useULYDispatch(caseId?: string | null) {
  const { data: caseServiceCall, loading, refetch, error } = useULYDispatchQuery(caseId)

  return {
    dispatch: caseServiceCall,
    loading,
    error,
    refetch,
  }
}

export default useULYDispatch
