// @ts-ignore
import * as React from 'react'
import * as Yup from 'yup'
import * as Ariakit from '@ariakit/react'
import { FormApi } from 'final-form'
import { Field as FieldValue } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FulfillmentSystem } from 'types/ticket'
import { CallTypes } from 'types/callstore'
import { Form, Submit } from 'components/FormManager'
import { Error, Condition, Checkbox } from 'components/fields'
import Input from 'components/Input'
import Margin from 'components/Margin'
import TransferSheetLink from 'components/TransferSheetLink'
import Section from 'components/Section'
import { useReduxCallStoreActions } from 'hooks/events'
import CallTypeSelection from './CallTypeSelection'
import ExchangeForm from './ExchangeForm/ExchangeForm'
import LawForm from './LawEnforcement/LawForm'
import EscalateForm from './EscalateForm'
import VehicleRecovery from './VehicleRecovery'
import FuelService from './FuelService'
import JumpstartService from './JumpstartService'
import LDR from './LDR'
import Winch from './Winch'
import TireRepair from './TireRepair'
import LockoutForm from './LockoutForm'
import Accident from './Accident'
import TowForm from './TowForm'
import { EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events/call/useCallEvents'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState, selectTabsState, selectTicket } from 'redux/appStore'
import { updateTabsState } from 'redux/slices/appSlice'
import { TabsState } from 'types/global'

declare module 'types/form' {
  export interface Forms {
    CallForm: FormApi
  }
}

type CallFormProps = {
  canSubmit?: boolean
  onSubmit: (values: object) => void
}

const callForms = {
  [CallTypes.EXCHANGE]: ExchangeForm,
  [CallTypes.RECOVERY]: VehicleRecovery,
  [CallTypes.FUEL]: FuelService,
  [CallTypes.JUMPSTART]: JumpstartService,
  [CallTypes.LAW]: LawForm,
  [CallTypes.ESCALATE]: EscalateForm,
  [CallTypes.LDR]: LDR,
  [CallTypes.KEYS]: LockoutForm,
  [CallTypes.WINCH]: Winch,
  [CallTypes.TIRES]: TireRepair,
  [CallTypes.ACCIDENT]: Accident,
  [CallTypes.TOW]: TowForm,
}

const WorkflowForms = React.memo(({ callType }: { callType: string }) => {
  const Component = callForms[callType]

  return (
    <Condition key={callType} when="callType" is={callType}>
      <Margin spacing="md">
        <Section id="formEntry">
          <Section.Body>
            <Component />
          </Section.Body>
        </Section>
      </Margin>
    </Condition>
  )
})

export const CallForm: React.FC<CallFormProps> = ({ onSubmit, canSubmit = true }) => {
  const { t } = useTranslation()
  const stepStore = Ariakit.useCompositeStore({ defaultActiveId: '1' })
  const dispatch = useDispatch()
  const { callType, notes, qspIsOpen } = useSelector(selectFormsState) as any
  const tabs = useSelector(selectTabsState)
  const setTabs = (newState: { tabs: TabsState }) => dispatch(updateTabsState(newState))
  const { fulfillmentSystem } = useSelector(selectTicket)
  const { update } = useReduxCallStoreActions()
  const lawEvent = useCallEvent(EventTypes.LAW)
  const lawNotes = lawEvent?.event?.eventType === EventTypes.LAW && lawEvent.event.attributes.notes

  return (
    <Form
      name="CallForm"
      schema={callLogSchema}
      initialValues={{ callType, notes, qspIsOpen }}
      subscription={{ submitting: true }}
      autoSaveSync={({ callType, notes, qspIsOpen }) => {
        setTabs({
          tabs: tabs.map((tab) => {
            if (tab.name === callType) {
              return { name: tab.name, enabled: true }
            } else return { name: tab.name, enabled: false }
          }),
        })
        return update({ callType, notes, qspIsOpen })
      }}
    >
      {() => (
        <Ariakit.CompositeProvider store={stepStore}>
          <Ariakit.Composite store={stepStore}>
            {/* Capture Call Type */}
            <Margin spacing="md">
              <Section id="callTypeTop" title={t('Call Type')}>
                <Section.Body>
                  <CallTypeSelection />
                  <Error name="callType" />
                </Section.Body>
              </Section>
            </Margin>
            {Object.entries(callForms).map(([callType]) => (
              <WorkflowForms callType={callType} />
            ))}

            {/* Capture Notes */}
            <Section title={t('End Call')}>
              <Section.Body>
                <Condition when="callType" is={CallTypes.TRANSFER}>
                  <Margin spacing="md">
                    <TransferSheetLink />
                  </Margin>
                </Condition>
                {(fulfillmentSystem === FulfillmentSystem.ODYSSEY || callType === CallTypes.CALLBACK) && (
                  <Margin spacing="md">
                    <Checkbox name="qspIsOpen" label={t('labels.qspIsOpen')} />
                  </Margin>
                )}
                {callType !== CallTypes.LAW ? (
                  <Input.Textarea name="notes" label={`${t('labels.branchNotes')}*`} maxLength={400} />
                ) : (
                  <Input.Hidden name="notes" defaultValue={lawNotes} />
                )}
                <FieldValue name="callType" subscription={{ value: true }}>
                  {({ input: { value: callType } }) => (
                    <Submit onSubmit={onSubmit} callType={callType} disabled={!canSubmit}>
                      {t('End Call')}
                    </Submit>
                  )}
                </FieldValue>
              </Section.Body>
            </Section>
          </Ariakit.Composite>
        </Ariakit.CompositeProvider>
      )}
    </Form>
  )
}

const callLogSchema = Yup.object().shape({
  callType: Yup.string().nullable().required('Call Type selection required'),
  notes: Yup.string().nullable().required('Notes are required'),
})

export default CallForm
