import React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import { AssignmentNetworks } from 'types/global'
import { useReduxCallStoreActions } from 'hooks/events'
import { Form } from 'components/FormManager'
import Margin from 'components/Margin'
import { VehicleSelect } from 'components/Select'
import Divider from 'components/Divider'
import { Tow, TowType, CallTypes } from 'types/callstore'
import { NetworkNames } from 'components/Select/NetworkSelect'
import { isRequired } from 'utils/validators'
import NoExchangeSteps from './NoExchangeSteps'
import NoExchangeInfo from './NoExchangeInfo'
import VehicleCondition from 'components/VehicleCondition'
import Input from 'components/Input'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    [TowType.NO_EXCHANGE]: FormApi
  }
}

export const NoExchange: React.FC = () => {
  const { t } = useTranslation()
  const { update } = useReduxCallStoreActions()
  const { tow, workflow } = useSelector(selectFormsState) as any

  const accidentInitialValue =
    workflow?.length > 0 && workflow[0] === CallTypes.ACCIDENT
      ? ({
          network: {
            label: NetworkNames[AssignmentNetworks.BODY],
            value: AssignmentNetworks.BODY,
          },
          ...tow,
        } as Tow)
      : null

  return (
    <Form
      name={TowType.NO_EXCHANGE}
      schema={towSchema}
      initialValues={accidentInitialValue}
      autoSaveSync={(tow) => update({ tow })}
    >
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
      <VehicleCondition />
      <NoExchangeInfo />
      <Margin use={Divider} spacing="md" />
      <NoExchangeSteps />
    </Form>
  )
}

const towSchema = Yup.object({
  notes: Yup.string().required('Notes for provider are required'),
})

export default NoExchange
